import { TopSectionLogoNewYearGame } from './TopSectionLogoNewYearGame'
import { TopSectionLogoTreasureIslandGame } from './TopSectionLogoTreasureIslandGame'
import { TopSectionLogoKDPayGame } from './TopSectionLogoKDPayGame'
import { TopSectionLogoNewYearGame2024_2025 } from './TopSectionLogoNewYearGame2024_2025'

const TopSectionLogoByGameId = {
  1: TopSectionLogoNewYearGame,
  2: TopSectionLogoTreasureIslandGame,
  3: TopSectionLogoKDPayGame,
  4: TopSectionLogoNewYearGame2024_2025,
}

const gameId = process.env.REACT_APP_GAME_ID
export const TopSectionLogo = TopSectionLogoByGameId[gameId]
