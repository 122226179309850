import { KeyDescriptionWrapperNewYearGame } from './KeyDescriptionWrapperNewYearGame'
import { KeyDescriptionWrapperTreasureIslandGame } from './KeyDescriptionWrapperTreasureIslandGame'
import { KeyDescriptionWrapperKDPayGame } from './KeyDescriptionWrapperKDPayGame'
import { KeyDescriptionWrapperNewYearGame2024_2025 } from './KeyDescriptionWrapperNewYearGame2024_2025'

const KeyDescriptionWrapperByGameId = {
  1: KeyDescriptionWrapperNewYearGame,
  2: KeyDescriptionWrapperTreasureIslandGame,
  3: KeyDescriptionWrapperKDPayGame,
  4: KeyDescriptionWrapperNewYearGame2024_2025,
}

const gameId = process.env.REACT_APP_GAME_ID
export const KeyDescriptionWrapper = KeyDescriptionWrapperByGameId[gameId]
