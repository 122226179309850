import { useLogoBackgroundSizeNewYearGame } from './useLogoBackgroundSizeNewYearGame'
import { useLogoBackgroundSizeTreasureIslandGame } from './useLogoBackgroundSizeTreasureIslandGame'
import { useLogoBackgroundSizeKDPayGame } from './useLogoBackgroundSizeKDPayGame'
import { useLogoBackgroundSizeNewYearGame2024_2025 } from './useLogoBackgroundSizeNewYearGame2024_2025'

const useLogoBackgroundSizeByGameId = {
  1: useLogoBackgroundSizeNewYearGame,
  2: useLogoBackgroundSizeTreasureIslandGame,
  3: useLogoBackgroundSizeKDPayGame,
  4: useLogoBackgroundSizeNewYearGame2024_2025,
}

const gameId = process.env.REACT_APP_GAME_ID
export const useLogoBackgroundSize = useLogoBackgroundSizeByGameId[gameId]
