import { headingPropsNewYearGame } from './headingPropsNewYearGame'
import { headingPropsTreasureIslandGame } from './headingPropsTreasureIslandGame'
import { headingPropsKDPayGame } from './headingPropsKDPayGame'
import { headingPropsNewYearGame2024_2025 } from './headingPropsNewYearGame2024_2025'

const headingPropsByGameId = {
  1: headingPropsNewYearGame,
  2: headingPropsTreasureIslandGame,
  3: headingPropsKDPayGame,
  4: headingPropsNewYearGame2024_2025,
}

const gameId = process.env.REACT_APP_GAME_ID
export const headingProps = headingPropsByGameId[gameId]
