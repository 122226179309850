import styled from 'styled-components'

export const ActionDescriptionWrapperNewYearGame2024_2025 = styled.div`
  width: 267px;
  margin: 0 15px 20px 15px;
  overflow-x: hidden;
  overflow-y: auto;
  font-weight: 400;
  font-size: 17px;
  line-height: 22px;
  color: ${({ theme }) => theme.colors.blue[2]};
`
