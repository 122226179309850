import { useHeadingTextContainerMarginBottomNewYearGame } from './useHeadingTextContainerMarginBottomNewYearGame'
import { useHeadingTextContainerMarginBottomTreasureIslandGame } from './useHeadingTextContainerMarginBottomTreasureIslandGame'
import { useHeadingTextContainerMarginBottomKDPayGame } from './useHeadingTextContainerMarginBottomKDPayGame'
import { useHeadingTextContainerMarginBottomNewYearGame2024_2025 } from './useHeadingTextContainerMarginBottomNewYearGame2024_2025'

const useHeadingTextContainerMarginBottomByGameId = {
  1: useHeadingTextContainerMarginBottomNewYearGame,
  2: useHeadingTextContainerMarginBottomTreasureIslandGame,
  3: useHeadingTextContainerMarginBottomKDPayGame,
  4: useHeadingTextContainerMarginBottomNewYearGame2024_2025,
}

const gameId = process.env.REACT_APP_GAME_ID
export const useHeadingTextContainerMarginBottom =
  useHeadingTextContainerMarginBottomByGameId[gameId]
