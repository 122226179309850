import styled from 'styled-components'

export const PopupInfoFrontLogoNewYearGame = styled.img`
  position: absolute;
  top: -152px;
  left: 50%;
  transform: translateX(-50%);
  height: auto;
  width: 254px;
`
