import { KeysBoxWrapperNewYearGame } from './KeysBoxWrapperNewYearGame'
import { KeysBoxWrapperTreasureIslandGame } from './KeysBoxWrapperTreasureIslandGame'
import { KeysBoxWrapperKDPayGame } from './KeysBoxWrapperKDPayGame'
import { KeysBoxWrapperNewYearGame2024_2025 } from './KeysBoxWrapperNewYearGame2024_2025'

const KeysBoxWrapperByGameId = {
  1: KeysBoxWrapperNewYearGame,
  2: KeysBoxWrapperTreasureIslandGame,
  3: KeysBoxWrapperKDPayGame,
  4: KeysBoxWrapperNewYearGame2024_2025,
}

const gameId = process.env.REACT_APP_GAME_ID
export const KeysBoxWrapper = KeysBoxWrapperByGameId[gameId]
