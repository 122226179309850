import { PopupPanelNewYearGame } from './PopupPanelNewYearGame'
import { PopupPanelTreasureIslandGame } from './PopupPanelTreasureIslandGame'
import { PopupPanelKDPayGame } from './PopupPanelKDPayGame'
import { PopupPanelNewYearGame2024_2025 } from './PopupPanelNewYearGame2024_2025'

const PopupPanelByGameId = {
  1: PopupPanelNewYearGame,
  2: PopupPanelTreasureIslandGame,
  3: PopupPanelKDPayGame,
  4: PopupPanelNewYearGame2024_2025,
}

const gameId = process.env.REACT_APP_GAME_ID
export const PopupPanel = PopupPanelByGameId[gameId]
