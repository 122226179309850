import { BoardSceneWrapperNewYearGame } from './BoardSceneWrapperNewYearGame'
import { BoardSceneWrapperTreasureIslandGame } from './BoardSceneWrapperTreasureIslandGame'
import { BoardSceneWrapperKDPayGame } from './BoardSceneWrapperKDPayGame'
import { BoardSceneWrapperNewYearGame2024_2025 } from './BoardSceneWrapperNewYearGame2024_2025'

const BoardSceneWrapperByGameId = {
  1: BoardSceneWrapperNewYearGame,
  2: BoardSceneWrapperTreasureIslandGame,
  3: BoardSceneWrapperKDPayGame,
  4: BoardSceneWrapperNewYearGame2024_2025,
}

const gameId = process.env.REACT_APP_GAME_ID
export const BoardSceneWrapper = BoardSceneWrapperByGameId[gameId]
