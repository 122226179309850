import useMatchMedia from 'react-use-match-media'
import { useMemo } from 'react'
import cloneDeep from 'lodash.clonedeep'

import { config } from 'config'

export const useMobileBackgroundNewYearGame2024_2025 = ({ calendarType }) => {
  const isLaptopViewport = useMatchMedia('(min-width: 768px)')

  const background = useMemo(() => {
    let background
    if (isLaptopViewport) {
      background = config.backgrounds.CALENDAR_DESKTOP_BACKGROUND
    } else {
      background = config.backgrounds.CALENDAR_MOBILE_BACKGROUND
    }

    return background
  }, [isLaptopViewport])

  return background
}
