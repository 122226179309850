import { useCallback, useState } from 'react'

import { useWindowResize } from 'hooks/useWindowResize'

const heightBreakpointPx = 785

export const useHeadingTextContainerMarginBottomNewYearGame2024_2025 = () => {
  const [windowHeight, setWindowHeight] = useState(window.innerHeight)

  const handleWindowResize = useCallback(() => {
    setWindowHeight(window.innerHeight)
  }, [])

  useWindowResize(handleWindowResize)

  let containerMarginBottom = null
  if (heightBreakpointPx >= windowHeight) {
    containerMarginBottom = '17px'
  }

  return containerMarginBottom ? { mb: containerMarginBottom } : null
}
