import { routesNewYearGame } from './routesNewYearGame'
import { routesTreasureIslandGame } from './routesTreasureIslandGame'
import { routesKDPayGame } from './routesKDPayGame'
import { routesNewYearGame2024_2025 } from './routesNewYearGame2024_2025'

const routesByGameId = {
  1: routesNewYearGame,
  2: routesTreasureIslandGame,
  3: routesKDPayGame,
  4: routesNewYearGame2024_2025,
}

const gameId = process.env.REACT_APP_GAME_ID
export const ROUTES = routesByGameId[gameId]
