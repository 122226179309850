import { headingTextPropsNewYearGame } from './headingTextPropsNewYearGame'
import { headingTextPropsTreasureIslandGame } from './headingTextPropsTreasureIslandGame'
import { headingTextPropsKDPayGame } from './headingTextPropsKDPayGame'
import { headingTextPropsNewYearGame2024_2025 } from './headingTextPropsNewYearGame2024_2025'

const headingTextPropsByGameId = {
  1: headingTextPropsNewYearGame,
  2: headingTextPropsTreasureIslandGame,
  3: headingTextPropsKDPayGame,
  4: headingTextPropsNewYearGame2024_2025,
}

const gameId = process.env.REACT_APP_GAME_ID
export const headingTextProps = headingTextPropsByGameId[gameId]
