import * as R from 'ramda'
import { renameKeys } from './common'

export const onceWithTimeout = (callback, timeout) => {
  let canCall = true
  return (...props) => {
    if (canCall) {
      callback(...props)
      canCall = false
      setTimeout(() => {
        canCall = true
      }, timeout)
    }
  }
}
