export const CALENDAR_GIFTS_POSITION = {
  1: [
    {
      id: 1,
      position: {
        mobile: { bottom: '69.8%', left: '9%' },
        desktop: { bottom: '63%', left: '15%' },
      },
    },
    {
      id: 2,
      position: {
        mobile: { bottom: '69.8%', left: '37%' },
        desktop: { bottom: '63%', left: '24.8%' },
      },
    },
    {
      id: 3,
      position: {
        mobile: { bottom: '69.8%', left: '64.5%' },
        desktop: { bottom: '63%', left: '34.5%' },
      },
    },
    {
      id: 4,
      position: {
        mobile: { bottom: '52.4%', left: '9%' },
        desktop: { bottom: '45.6%', left: '15%' },
      },
    },
    {
      id: 5,
      position: {
        mobile: { bottom: '52.4%', left: '37%' },
        desktop: { bottom: '45.6%', left: '24.8%' },
      },
    },
    {
      id: 6,
      position: {
        mobile: { bottom: '52.4%', left: '64.5%' },
        desktop: { bottom: '45.6%', left: '34.6%' },
      },
    },
    {
      id: 7,
      position: {
        mobile: { bottom: '35.3%', left: '9%' },
        desktop: { bottom: '28.2%', left: '15%' },
      },
    },
    {
      id: 8,
      position: {
        mobile: { bottom: '35.3%', left: '37%' },
        desktop: { bottom: '28.2%', left: '24.8%' },
      },
    },
    {
      id: 9,
      position: {
        mobile: { bottom: '35.3%', left: '64.5%' },
        desktop: { bottom: '28.2%', left: '34.6%' },
      },
    },
    {
      id: 10,
      position: {
        mobile: { bottom: '18%', left: '9%' },
        desktop: { bottom: '10.4%', left: '15%' },
      },
    },
    {
      id: 11,
      position: {
        mobile: { bottom: '18%', left: '37%' },
        desktop: { bottom: '10.4%', left: '24.8%' },
      },
    },
    {
      id: 12,
      position: {
        mobile: { bottom: '18%', left: '64.5%' },
        desktop: { bottom: '10.4%', left: '34.6%' },
      },
    },
  ],

  2: [
    {
      id: 1,
      position: {
        mobile: { bottom: '67.9%', left: '29.85%' },
        desktop: { bottom: '63%', left: '15%' },
      },
    },
    {
      id: 2,
      position: {
        mobile: { bottom: '67.9%', left: '43.55%' },
        desktop: { bottom: '63%', left: '24.8%' },
      },
    },
    {
      id: 3,
      position: {
        mobile: { bottom: '67.9%', left: '57%' },
        desktop: { bottom: '63%', left: '34.5%' },
      },
    },
    {
      id: 4,
      position: {
        mobile: { bottom: '50%', left: '29.85%' },
        desktop: { bottom: '45.6%', left: '15%' },
      },
    },
    {
      id: 5,
      position: {
        mobile: { bottom: '50%', left: '43.55%' },
        desktop: { bottom: '45.6%', left: '24.8%' },
      },
    },
    {
      id: 6,
      position: {
        mobile: { bottom: '50%', left: '57%' },
        desktop: { bottom: '45.6%', left: '34.6%' },
      },
    },
    {
      id: 7,
      position: {
        mobile: { bottom: '31.6%', left: '29.85%' },
        desktop: { bottom: '28.2%', left: '15%' },
      },
    },
    {
      id: 8,
      position: {
        mobile: { bottom: '31.6%', left: '43.55%' },
        desktop: { bottom: '28.2%', left: '24.8%' },
      },
    },
    {
      id: 9,
      position: {
        mobile: { bottom: '31.6%', left: '57%' },
        desktop: { bottom: '28.2%', left: '34.6%' },
      },
    },
    {
      id: 10,
      position: {
        mobile: { bottom: '13.4%', left: '29.85%' },
        desktop: { bottom: '10.4%', left: '15%' },
      },
    },
    {
      id: 11,
      position: {
        mobile: { bottom: '13.4%', left: '43.55%' },
        desktop: { bottom: '10.4%', left: '24.8%' },
      },
    },
    {
      id: 12,
      position: {
        mobile: { bottom: '13.4%', left: '57%' },
        desktop: { bottom: '10.4%', left: '34.6%' },
      },
    },
  ],

  3: [
    {
      id: 1,
      position: {
        mobile: { bottom: '69%', left: '19.6%' },
        desktop: { bottom: '63%', left: '15%' },
      },
    },
    {
      id: 2,
      position: {
        mobile: { bottom: '69%', left: '40.6%' },
        desktop: { bottom: '63%', left: '24.8%' },
      },
    },
    {
      id: 3,
      position: {
        mobile: { bottom: '69%', left: '61%' },
        desktop: { bottom: '63%', left: '34.5%' },
      },
    },
    {
      id: 4,
      position: {
        mobile: { bottom: '51.6%', left: '19.6%' },
        desktop: { bottom: '45.6%', left: '15%' },
      },
    },
    {
      id: 5,
      position: {
        mobile: { bottom: '51.6%', left: '40.6%' },
        desktop: { bottom: '45.6%', left: '24.8%' },
      },
    },
    {
      id: 6,
      position: {
        mobile: { bottom: '51.6%', left: '61%' },
        desktop: { bottom: '45.6%', left: '34.6%' },
      },
    },
    {
      id: 7,
      position: {
        mobile: { bottom: '33.6%', left: '19.6%' },
        desktop: { bottom: '28.2%', left: '15%' },
      },
    },
    {
      id: 8,
      position: {
        mobile: { bottom: '33.6%', left: '40.6%' },
        desktop: { bottom: '28.2%', left: '24.8%' },
      },
    },
    {
      id: 9,
      position: {
        mobile: { bottom: '33.6%', left: '61%' },
        desktop: { bottom: '28.2%', left: '34.6%' },
      },
    },
    {
      id: 10,
      position: {
        mobile: { bottom: '15.6%', left: '19.6%' },
        desktop: { bottom: '10.4%', left: '15%' },
      },
    },
    {
      id: 11,
      position: {
        mobile: { bottom: '15.6%', left: '40.6%' },
        desktop: { bottom: '10.4%', left: '24.8%' },
      },
    },
    {
      id: 12,
      position: {
        mobile: { bottom: '15.6%', left: '61%' },
        desktop: { bottom: '10.4%', left: '34.6%' },
      },
    },
  ],
}
