import { keyImgPropsNewYearGame } from './keyImgPropsNewYearGame'
import { keyImgPropsTreasureIslandGame } from './keyImgPropsTreasureIslandGame'
import { keyImgPropsKDPayGame } from './keyImgPropsKDPayGame'
import { keyImgPropsNewYearGame2024_2025 } from './keyImgPropsNewYearGame2024_2025'

const keyImgPropsByGameId = {
  1: keyImgPropsNewYearGame,
  2: keyImgPropsTreasureIslandGame,
  3: keyImgPropsKDPayGame,
  4: keyImgPropsNewYearGame2024_2025,
}

const gameId = process.env.REACT_APP_GAME_ID
export const keyImgProps = keyImgPropsByGameId[gameId]
