import styled, { keyframes } from 'styled-components'

const Loader = styled.div`
  width: 600px;
  height: 600px;
  background-image: url(/img/newYearGame2024_2025/thematic_loader.gif);
  background-position: center center;
  background-size: contain;
  background-repeat: no-repeat;
  margin: ${props => props.margin || '0'};
`

export const PreloaderThematicNewYearGame2024_2025 = ({ margin }) => {
  return <Loader margin={margin} />
}

PreloaderThematicNewYearGame2024_2025.defaultProps = {}
