import { CalendarListItemIndicatorNewYearGame } from './CalendarListItemIndicatorNewYearGame'
import { CalendarListItemIndicatorTreasureIslandGame } from './CalendarListItemIndicatorTreasureIslandGame'
import { CalendarListItemIndicatorKDPayGame } from './CalendarListItemIndicatorKDPayGame'
import { CalendarListItemIndicatorNewYearGame2024_2025 } from './CalendarListItemIndicatorNewYearGame2024_2025'

const CalendarListItemIndicatorByGameId = {
  1: CalendarListItemIndicatorNewYearGame,
  2: CalendarListItemIndicatorTreasureIslandGame,
  3: CalendarListItemIndicatorKDPayGame,
  4: CalendarListItemIndicatorNewYearGame2024_2025,
}

const gameId = process.env.REACT_APP_GAME_ID
export const CalendarListItemIndicator =
  CalendarListItemIndicatorByGameId[gameId]
