import { useMobileBackgroundNewYearGame } from './useMobileBackgroundNewYearGame'
import { useMobileBackgroundTreasureIslandGame } from './useMobileBackgroundTreasureIslandGame'
import { useMobileBackgroundKDPayGame } from './useMobileBackgroundKDPayGame'
import { useMobileBackgroundNewYearGame2024_2025 } from './useMobileBackgroundNewYearGame2024_2025'

const useMobileBackgroundByGameId = {
  1: useMobileBackgroundNewYearGame,
  2: useMobileBackgroundTreasureIslandGame,
  3: useMobileBackgroundKDPayGame,
  4: useMobileBackgroundNewYearGame2024_2025,
}

const gameId = process.env.REACT_APP_GAME_ID
export const useMobileBackground = useMobileBackgroundByGameId[gameId]
