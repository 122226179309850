import theme from 'ui/settings/theme'

export const headingTextPropsNewYearGame2024_2025 = {
  mb: '16px',
  fontSize: '16px',
  fontWeight: '400',
  lineHeight: '24px',
  textAlign: 'center',
  color: theme.colors.black[0],
}
