import styled from 'styled-components'

export const KeyWrapperNewYearGame2024_2025 = styled.div`
  width: 13vw;
  max-width: 65px;
  transform: translate(0, 0);
  z-index: 10;

  background-image: url(/img/newYearGame2024_2025/hammer.png);
  background-size: contain;
  cursor: grab;

  opacity: ${props => (props.isDragging ? 0 : 1)};

  &:before {
    content: '';

    display: block;
    padding-top: 100%;
  }

  @media (min-width: 768px) {
    width: 81px;
  }

  @media (min-width: 1903px) {
    width: 4.2vw;
  }
`
