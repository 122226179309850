import { useCallback, useState } from 'react'

import { useWindowResize } from 'hooks/useWindowResize'
import { config } from 'config'

const heightBreakpointPx = 655

export const useLayoutMobileBodyPropsNewYearGame2024_2025 = () => {
  const [windowHeight, setWindowHeight] = useState(window.innerHeight)

  const handleWindowResize = useCallback(() => {
    setWindowHeight(window.innerHeight)
  }, [])

  useWindowResize(handleWindowResize)

  let useLayoutMobileBodyProps = null
  if (heightBreakpointPx >= windowHeight) {
    useLayoutMobileBodyProps = { layoutMobileBodyProps: { pt: '13px' } }
  }

  return useLayoutMobileBodyProps ? useLayoutMobileBodyProps : null
}
