import { KeyWrapperNewYearGame } from './KeyWrapperNewYearGame'
import { KeyWrapperTreasureIslandGame } from './KeyWrapperTreasureIslandGame'
import { KeyWrapperKDPayGame } from './KeyWrapperKDPayGame'
import { KeyWrapperNewYearGame2024_2025 } from './KeyWrapperNewYearGame2024_2025'

const KeyWrapperByGameId = {
  1: KeyWrapperNewYearGame,
  2: KeyWrapperTreasureIslandGame,
  3: KeyWrapperKDPayGame,
  4: KeyWrapperNewYearGame2024_2025,
}

const gameId = process.env.REACT_APP_GAME_ID
export const KeyWrapper = KeyWrapperByGameId[gameId]
