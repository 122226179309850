import styled, { css } from 'styled-components'

export const PointWrapperNewYearGame2024_2025 = styled.div`
  position: absolute;
  bottom: ${props => props.position.mobile.bottom || '0'};
  left: ${props => props.position.mobile.left || '0'};

  width: ${({ calendarType }) => {
    if (calendarType === 1) {
      return '26%'
    } else if (calendarType === 2) {
      return '13%'
    } else {
      return '19%'
    }
  }};
  border-radius: 9999px;
  background-color: transparent;
  transition: background-color 0.3s ease;

  @media (min-width: 768px) {
    bottom: ${props => props.position.desktop.bottom || '0'};
    left: ${props => props.position.desktop.left || '0'};
    width: 9%;
  }

  &:before {
    content: '';

    display: block;
    padding-top: 100%;
  }

  &:after {
    content: '';

    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-image: ${({ $imgName }) => `url(${$imgName})`};
    background-size: contain;
    background-repeat: no-repeat;
    transition: opacity 0.3s ease;
  }
`
