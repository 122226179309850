import styled, { css } from 'styled-components'

export const BoardSceneNewYearGame2024_2025 = styled.img`
  pointer-events: none;
  user-select: none;

  ${({ isWideViewport, calendarType }) => {
    if (isWideViewport) {
      return css`
        width: 100vw;
        height: auto;
      `
    } else {
      if (calendarType === 1) {
        return css`
          max-height: calc(100vh - 200px);
          max-height: calc(100dvh - 200px);
          max-width: calc(100vw + 57px);
          height: auto;
        `
      } else if (calendarType === 2) {
        return css`
          max-height: calc(100vh - 200px);
          max-height: calc(100dvh - 200px);
          max-width: calc(100vw + 550px);
          height: auto;
        `
      } else {
        return css`
          max-height: calc(100vh - 200px);
          max-height: calc(100dvh - 200px);
          max-width: calc(100vw + 192px);
          height: auto;
        `
      }
    }
  }}
`
