export const texts = {
  calendarsPage: {
    pageHeading: 'Mои календари',
    availableKeys: 'Доступных ключиков',
    keysDescription:
      'Открывай ключиками дни в адвент-календаре, чтобы получить золотые билеты для розыгрыша!',
    listItemTitle: 'Календарь',
    errorMsgTop: 'Упс! Произошла ошибка',
    errorMsgBottom: 'загрузки твоих календарей',
  },
  homePage: {
    headingDesktop: ['Открывай окошки ', 'календаря ', 'и собирай подарки'],
    headingMobile: ['Открывай ', 'окошки календаря ', 'и собирай подарки'],
    headingMobileShorter: [''],
    headingAlt: 'Открывай окошки календаря и собирай подарки',
    toCalendars: 'К календарю',
  },
  popups: {
    keysReminder: {
      heading: ['Не забудь про ключики!'],
      contentSectionTop:
        'Открывай ключиками окошки календаря и забирай подарки',
      contentSectionBottom:
        'Тебя ждёт гарантированный приятный сюрприз за каждым окошком!',
      keys: 'Ключики',
      button: 'За подарками!',
    },
    ticketGift: {
      heading: ['Золотой билет'],
      subheading: [],
      button: 'Закрыть',
    },
    fortuneGift: {
      heading: ['А вот и твой подарок!'],
      subheading: [],
    },
    couponGift: {
      heading: ['А вот и твой подарок!'],
      subheading: [],
    },
    promocodeGift: {
      heading: ['А вот и твой подарок!'],
      subheading: [],
    },
    calendarError: {
      heading: 'Упс!',
      content: 'Произошла ошибка загрузки календаря',
    },
    giftError: {
      heading: 'Упс!',
      content: 'Произошла ошибка при открытии подарка',
    },
    rules: {
      heading: [],
      content: 'Прежде чем начать игру, ознакомься с ',
    },
    notHaveKeys: {
      heading: 'У тебя пока нет ключиков :(',
      content:
        'Покупай на сумму от 800₽ или товары-спонсоры в магазинах SPAR, Победа и Семья, чтобы получить их',
    },
    allDaysOpened: {
      heading: 'Все дни открыты!',
      content:
        'Переходи к следующему календарю, чтобы получить ещё больше подарков!',
      button: 'Перейти',
    },
    gameStartingDate: {
      heading: ['Добро пожаловать!'],
      subheading: ['Готовим подарки!', 'Игра начнётся 14 октября'],
      button: 'Продолжить',
    },
  },
  keyDescription: {
    heading: 'Это золотой ключик',
    description: 'Чтобы открыть подарок, потяни его к коробке с замком',
  },
  keysBox: {
    keys: 'Ключики',
  },
  keysCarousel: {
    emptyMessage: 'Совершай покупки и получай ключики',
  },
}
