import { PopupInfoPanelNewYearGame } from './PopupInfoPanelNewYearGame'
import { PopupInfoPanelTreasureIslandGame } from './PopupInfoPanelTreasureIslandGame'
import { PopupInfoPanelKDPayGame } from './PopupInfoPanelKDPayGame'
import { PopupInfoPanelNewYearGame2024_2025 } from './PopupInfoPanelNewYearGame2024_2025'

const PopupInfoPanelByGameId = {
  1: PopupInfoPanelNewYearGame,
  2: PopupInfoPanelTreasureIslandGame,
  3: PopupInfoPanelKDPayGame,
  4: PopupInfoPanelNewYearGame2024_2025,
}

const gameId = process.env.REACT_APP_GAME_ID
export const PopupInfoPanel = PopupInfoPanelByGameId[gameId]
