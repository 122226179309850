import styled from 'styled-components'

export const PopupPanelNewYearGame2024_2025 = styled.div`
  position: relative;
  width: 100vw; // vw вместо % предотвращает схлопывание элемента
  height: 311px;
  max-width: 328px;
  padding-top: 36px;
  padding-bottom: 16px;
  padding-right: 16px;
  padding-left: 16px;
  background: linear-gradient(180deg, #ffd77b 0%, #ffcc58 100%);
  border-radius: 32px 32px 0 0;
  box-shadow: 0px 26px 45px 0px #ffd91e69;

  &:after {
    content: '';
    position: absolute;
    bottom: -38px;
    left: 0;
    width: 328px;
    height: 44px;
    background-image: url(/img/newYearGame2024_2025/ticket_gift_popup_bottom.png);
    background-repeat: no-repeat;
    background-position: top left;
    background-size: contain;
    z-index: 2;
  }
`
