import theme from 'ui/settings/theme'

export const ticketValueTextPropsNewYearGame2024_2025 = {
  mb: '16px',
  fontSize: '25px',
  fontWeight: '600',
  lineHeight: '34px',
  textAlign: 'center',
  color: theme.colors.blue[2],
  backgroundColor: theme.colors.white,
  borderRadius: '12px',
  padding: '8px 0',
}
