import theme from 'ui/settings/theme'

export const promocodeTextPropsNewYearGame2024_2025 = {
  fontSize: '16px',
  fontWeight: '400',
  lineHeight: '19px',
  letterSpacing: '0.5px',
  textAlign: 'center',
  color: theme.colors.blue[3],
}
