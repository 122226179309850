import styled from 'styled-components'

export const ActionDescriptionWrapperNewYearGame2024_2025 = styled.div`
  width: 267px;
  height: 105px;
  margin: 0 10px 20px 20px;
  overflow-x: hidden;
  overflow-y: auto;
  font-weight: 400;
  font-size: 17px;
  line-height: 22px;

  color: ${({ theme }) => theme.colors.white};

  &::-webkit-scrollbar {
    width: 20px;
    scroll-margin-left: 20px;
  }

  &::-webkit-scrollbar-track {
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    background-clip: padding-box;
    border-radius: 8px;
    background-color: rgba(255, 255, 255, 0.3);
  }

  &::-webkit-scrollbar-thumb {
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    background-clip: padding-box;
    border-radius: 8px;
    background-color: #fff;
  }
`
