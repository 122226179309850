import { BoardKeyPreviewWrapperNewYearGame } from './BoardKeyPreviewWrapperNewYearGame'
import { BoardKeyPreviewWrapperTreasureIslandGame } from './BoardKeyPreviewWrapperTreasureIslandGame'
import { BoardKeyPreviewWrapperKDPayGame } from './BoardKeyPreviewWrapperKDPayGame'
import { BoardKeyPreviewWrapperNewYearGame2024_2025 } from './BoardKeyPreviewWrapperNewYearGame2024_2025'

const BoardKeyPreviewWrapperByGameId = {
  1: BoardKeyPreviewWrapperNewYearGame,
  2: BoardKeyPreviewWrapperTreasureIslandGame,
  3: BoardKeyPreviewWrapperKDPayGame,
  4: BoardKeyPreviewWrapperNewYearGame2024_2025,
}

const gameId = process.env.REACT_APP_GAME_ID
export const BoardKeyPreviewWrapper = BoardKeyPreviewWrapperByGameId[gameId]
