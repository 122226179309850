import { useCallback, useMemo, useState } from 'react'

import { useWindowResize } from 'hooks/useWindowResize'
import { ticketsMobileContants } from '../constants'

const {
  DEFAULT_TICKETS_INFO_HEIGHT_PX,
  TICKETS_MARGIN_TOP_PX,
  TICKETS_MARGIN_BOTTOM_SMALLER_PX,
  TICKETS_MARGIN_BOTTOM_BIGGER_PX,
  TICKETS_DELTA_PX,
  MIN_TICKETS_BLOCK_HEIGHT_PX,
  WINDOW_HEIGHT_BREAKPOINT_SMALLER,
  WINDOW_HEIGHT_BREAKPOINT_BIGGER,
} = ticketsMobileContants

export const useTicketsHeightNewYearGame2024_2025 = ({
  hasError,
  isLoading,
  ticketsInfoRef,
}) => {
  const [ticketsInfoHeightPx, setTicketsInfoHeightPx] = useState(
    DEFAULT_TICKETS_INFO_HEIGHT_PX
  )
  const [windowHeight, setWindowHeight] = useState(window.innerHeight)

  const handleWindowResize = useCallback(() => {
    if (ticketsInfoRef.current) {
      const ticketsInfoDimensions = window.getComputedStyle(
        ticketsInfoRef.current
      )
      setTicketsInfoHeightPx(Number(ticketsInfoDimensions.height.slice(0, -2)))
    }

    setWindowHeight(window.innerHeight)
  }, [hasError, isLoading])

  useWindowResize(handleWindowResize)

  const ticketsHeight = useMemo(() => {
    let ticketsHeight
    if (
      WINDOW_HEIGHT_BREAKPOINT_BIGGER &&
      WINDOW_HEIGHT_BREAKPOINT_BIGGER <= windowHeight
    ) {
      const heightSubtractionValue =
        ticketsInfoHeightPx +
        TICKETS_MARGIN_TOP_PX +
        TICKETS_MARGIN_BOTTOM_BIGGER_PX -
        TICKETS_DELTA_PX

      ticketsHeight = `calc(100% - ${heightSubtractionValue}px)`
    } else if (
      MIN_TICKETS_BLOCK_HEIGHT_PX &&
      WINDOW_HEIGHT_BREAKPOINT_BIGGER &&
      WINDOW_HEIGHT_BREAKPOINT_SMALLER &&
      windowHeight >= WINDOW_HEIGHT_BREAKPOINT_SMALLER &&
      windowHeight < WINDOW_HEIGHT_BREAKPOINT_BIGGER
    ) {
      ticketsHeight = `${MIN_TICKETS_BLOCK_HEIGHT_PX}px`
    } else {
      const heightSubtractionValue =
        ticketsInfoHeightPx +
        TICKETS_MARGIN_TOP_PX +
        TICKETS_MARGIN_BOTTOM_SMALLER_PX -
        TICKETS_DELTA_PX
      ticketsHeight = `calc(100% - ${heightSubtractionValue}px)`
    }

    return ticketsHeight
  }, [windowHeight, ticketsInfoHeightPx])

  return ticketsHeight
}
