import { useCallback, useState } from 'react'

import { useWindowResize } from 'hooks/useWindowResize'
import { ticketsMobileContants } from '../constants'

const {
  DEFAULT_TICKETS_INFO_HEIGHT_PX,
  TICKETS_MARGIN_TOP_PX,
  TICKETS_MARGIN_BOTTOM_PX,
  TICKETS_DELTA_PX,
} = ticketsMobileContants

export const useTicketsHeightKDPayGame = ({
  hasError,
  isLoading,
  ticketsInfoRef,
}) => {
  const [ticketsInfoHeightPx, setTicketsInfoHeightPx] = useState(
    DEFAULT_TICKETS_INFO_HEIGHT_PX
  )

  const handleWindowResize = useCallback(() => {
    if (ticketsInfoRef.current) {
      const ticketsInfoDimensions = window.getComputedStyle(
        ticketsInfoRef.current
      )
      setTicketsInfoHeightPx(Number(ticketsInfoDimensions.height.slice(0, -2)))
    }
  }, [hasError, isLoading])

  useWindowResize(handleWindowResize)

  const heightSubtractionValue =
    ticketsInfoHeightPx +
    TICKETS_MARGIN_TOP_PX +
    TICKETS_MARGIN_BOTTOM_PX -
    TICKETS_DELTA_PX

  const ticketsHeight = `calc(100% - ${heightSubtractionValue}px)`

  return ticketsHeight
}
