import { defaultTheme } from '@xstyled/styled-components'

const breakpoints = ['375px', '768px', '992px', '1200px', '1322px', '1536px']

const theme = {
  ...defaultTheme,
  screens: {
    _: 0,
    xs: breakpoints[0],
    sm: breakpoints[1],
    md: breakpoints[2],
    lg: breakpoints[3],
    lgWide: breakpoints[4],
    xl: breakpoints[5],
  },
  mq: {
    xs: `(min-width: ${breakpoints[0]})`,
    sm: `(min-width: ${breakpoints[1]})`,
    md: `(min-width: ${breakpoints[2]})`,
    lg: `(min-width: ${breakpoints[3]})`,
    lgWide: `(min-width: ${breakpoints[4]})`,
    xl: `(min-width: ${breakpoints[5]})`,
  },
  fonts: {
    rubik: 'Rubik, sans-serif',
    rupsterscriptfree: 'Rupster Script Free, sans-serif',
    museosanscyrl: 'Museo Sans Cyrl, sans-serif',
  },
  colors: {
    white: '#fff',
    black: {
      0: '#000000',
      1: 'rgba(0, 0, 0, 0.1)',
      2: 'rgba(0, 0, 0, 0.2)',
      3: 'rgba(0, 0, 0, 0.25)',
      4: 'rgba(0, 0, 0, 0.15)',
    },
    red: {
      0: '#ED472F',
      1: '#E63535',
      2: '#701616',
      3: '#DE221F',
      4: '#B41703',
      5: '#F4302C',
    },
    blue: {
      0: '#103040',
      1: '#4F85AC',
      2: '#144055',
      3: 'rgba(20, 64, 85, 0.8)',
      4: '#83B6DC',
      5: '#0091B4',
      6: '#2877B5',
      7: '#17badf',
      8: 'rgba(20, 64, 85, 0.7)',
      9: '#38bde0',
      10: 'rgba(49, 190, 225, 0.68)',
      11: '#c0e3f3',
      12: '#b0d9ee',
      13: '#20bce1',
    },
    green: {
      0: '#71A82B',
    },
    brown: {
      0: '#8A3122',
      1: '#FEBC77',
      2: 'rgba(138, 49, 34, 0.4)',
      3: '#c7611d',
      4: '#E18A2F',
    },
    purple: {
      0: '#5E133C',
      1: '#4E2746',
      2: '#391329',
    },
    grey: {
      0: '#EBF1F1',
    },
    yellow: {
      0: 'rgba(241, 226, 147, 0.1)',
    },
    textPrimaryColor: '#d16600',
    textSecondaryColor: '#144055',
    textTertiaryColor: 'rgba(0, 0, 0, 0.25)',
  },
}

export default theme
