import { ActionDescriptionWrapperNewYearGame } from './ActionDescriptionWrapperNewYearGame'
import { ActionDescriptionWrapperTreasureIslandGame } from './ActionDescriptionWrapperTreasureIslandGame'
import { ActionDescriptionWrapperKDPayGame } from './ActionDescriptionWrapperKDPayGame'
import { ActionDescriptionWrapperNewYearGame2024_2025 } from './ActionDescriptionWrapperNewYearGame2024_2025'

const ActionDescriptionWrapperByGameId = {
  1: ActionDescriptionWrapperNewYearGame,
  2: ActionDescriptionWrapperTreasureIslandGame,
  3: ActionDescriptionWrapperKDPayGame,
  4: ActionDescriptionWrapperNewYearGame2024_2025,
}

const gameId = process.env.REACT_APP_GAME_ID
export const ActionDescriptionWrapper = ActionDescriptionWrapperByGameId[gameId]
