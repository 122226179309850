import { goldenTicketsPropsNewYearGame } from './goldenTicketsPropsNewYearGame'
import { goldenTicketsPropsTreasureIslandGame } from './goldenTicketsPropsTreasureIslandGame'
import { goldenTicketsPropsKDPayGame } from './goldenTicketsPropsKDPayGame'
import { goldenTicketsPropsNewYearGame2024_2025 } from './goldenTicketsPropsNewYearGame2024_2025'

const goldenTicketsPropsByGameId = {
  1: goldenTicketsPropsNewYearGame,
  2: goldenTicketsPropsTreasureIslandGame,
  3: goldenTicketsPropsKDPayGame,
  4: goldenTicketsPropsNewYearGame2024_2025,
}

const gameId = process.env.REACT_APP_GAME_ID
export const goldenTicketsProps = goldenTicketsPropsByGameId[gameId]
