import styled from 'styled-components'
import { LogoVariantsKDPayGame } from './LogoVariantsKDPayGame'

export const PopupInfoBackLogoKDPayGame = styled.div`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: 393px;
  height: 374px;
  background-size: contain;
  background-repeat: no-repeat;
  z-index: -1;
  ${LogoVariantsKDPayGame}
`
