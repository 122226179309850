import { useLayoutMobileBodyPropsNewYearGame } from './useLayoutMobileBodyPropsNewYearGame'
import { useLayoutMobileBodyPropsTreasureIslandGame } from './useLayoutMobileBodyPropsTreasureIslandGame'
import { useLayoutMobileBodyPropsKDPayGame } from './useLayoutMobileBodyPropsKDPayGame'
import { useLayoutMobileBodyPropsNewYearGame2024_2025 } from './useLayoutMobileBodyPropsNewYearGame2024_2025'

const useLayoutMobileBodyPropsByGameId = {
  1: useLayoutMobileBodyPropsNewYearGame,
  2: useLayoutMobileBodyPropsTreasureIslandGame,
  3: useLayoutMobileBodyPropsKDPayGame,
  4: useLayoutMobileBodyPropsNewYearGame2024_2025,
}

const gameId = process.env.REACT_APP_GAME_ID
export const useLayoutMobileBodyProps = useLayoutMobileBodyPropsByGameId[gameId]
