import { yourGiftTextPropsNewYearGame } from './yourGiftTextPropsNewYearGame'
import { yourGiftTextPropsTreasureIslandGame } from './yourGiftTextPropsTreasureIslandGame'
import { yourGiftTextPropsKDPayGame } from './yourGiftTextPropsKDPayGame'
import { yourGiftTextPropsNewYearGame2024_2025 } from './yourGiftTextPropsNewYearGame2024_2025'

const yourGiftTextPropsByGameId = {
  1: yourGiftTextPropsNewYearGame,
  2: yourGiftTextPropsTreasureIslandGame,
  3: yourGiftTextPropsKDPayGame,
  4: yourGiftTextPropsNewYearGame2024_2025,
}

const gameId = process.env.REACT_APP_GAME_ID
export const yourGiftTextProps = yourGiftTextPropsByGameId[gameId]
