import { useCallback, useState } from 'react'

import { useWindowResize } from 'hooks/useWindowResize'

const heightBreakpointPx = 616

export const useButtonsPyNewYearGame2024_2025 = () => {
  const [windowHeight, setWindowHeight] = useState(window.innerHeight)

  const handleWindowResize = useCallback(() => {
    setWindowHeight(window.innerHeight)
  }, [])

  useWindowResize(handleWindowResize)

  let useLayoutMobileBodyProps = null
  if (heightBreakpointPx >= windowHeight) {
    useLayoutMobileBodyProps = { py: '9px' }
  }

  return useLayoutMobileBodyProps ? useLayoutMobileBodyProps : null
}
