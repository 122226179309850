import { NavbarDesktopWrapperNewYearGame } from './NavbarDesktopWrapperNewYearGame'
import { NavbarDesktopWrapperTreasureIslandGame } from './NavbarDesktopWrapperTreasureIslandGame'
import { NavbarDesktopWrapperKDPayGame } from './NavbarDesktopWrapperKDPayGame'
import { NavbarDesktopWrapperNewYearGame2024_2025 } from './NavbarDesktopWrapperNewYearGame2024_2025'

const NavbarDesktopWrapperByGameId = {
  1: NavbarDesktopWrapperNewYearGame,
  2: NavbarDesktopWrapperTreasureIslandGame,
  3: NavbarDesktopWrapperKDPayGame,
  4: NavbarDesktopWrapperNewYearGame2024_2025,
}

const gameId = process.env.REACT_APP_GAME_ID
export const NavbarDesktopWrapper = NavbarDesktopWrapperByGameId[gameId]
