import { popupInfoPropsNewYearGame } from './popupInfoPropsNewYearGame'
import { popupInfoPropsTreasureIslandGame } from './popupInfoPropsTreasureIslandGame'
import { popupInfoPropsKDPayGame } from './popupInfoPropsKDPayGame'
import { popupInfoPropsNewYearGame2024_2025 } from './popupInfoPropsNewYearGame2024_2025'

const popupInfoPropsByGameId = {
  1: popupInfoPropsNewYearGame,
  2: popupInfoPropsTreasureIslandGame,
  3: popupInfoPropsKDPayGame,
  4: popupInfoPropsNewYearGame2024_2025,
}

const gameId = process.env.REACT_APP_GAME_ID
export const popupInfoProps = popupInfoPropsByGameId[gameId]
