import React, { useCallback, useRef, useState } from 'react'

import { Box, Flex } from 'ui/atoms'
import { TicketsError, TicketsInfo } from 'ui/molecules'
import { LayoutMobile } from 'ui/layout'
import { TicketList } from 'ui/organisms'
import { config } from 'config'
import { TextTitleTickets } from './TextTitleTickets'
import { BottomShadow } from './BottomShadow'
import { ticketsMobileContants } from './constants'
import { useTicketsHeight } from './useTicketsHeight'

const { DEFAULT_TICKETS_INFO_HEIGHT_PX, TICKETS_MARGIN_TOP_PX } =
  ticketsMobileContants

export const TicketsMobile = React.forwardRef(
  ({ tickets, isLoading, hasError, onRetry, isWideViewport }, ref) => {
    const [ticketsInfoHeightPx, setTicketsInfoHeightPx] = useState(
      DEFAULT_TICKETS_INFO_HEIGHT_PX
    )

    const ticketsInfoRef = useRef()
    const ticketsHeight = useTicketsHeight({
      hasError,
      isLoading,
      ticketsInfoRef,
    })

    return (
      <LayoutMobile ref={ref} {...config.backgrounds.TICKETS_MOBILE_BACKGROUND}>
        <LayoutMobile.Header mb="16px">
          <TextTitleTickets>Мои билеты</TextTitleTickets>
        </LayoutMobile.Header>

        <LayoutMobile.Content>
          <Box height="100%" maxHeight="100%">
            {!hasError && !isLoading && (
              <TicketsInfo variant="mobile" ref={ticketsInfoRef} />
            )}
            {(isLoading || hasError) && (
              <Flex
                position="relative"
                mt="150px"
                w="100%"
                mx="auto"
                maxWidth="465px"
                flexGrow="1"
                flexDirection="column"
              >
                <TicketsError
                  isLoading={isLoading}
                  hasError={hasError}
                  onRetry={onRetry}
                />
              </Flex>
            )}
            {!isLoading && !hasError && (
              <Box
                mt={`${TICKETS_MARGIN_TOP_PX}px`}
                h={ticketsHeight}
                maxHeight={ticketsHeight}
              >
                <TicketList tickets={tickets} isWideViewport={isWideViewport} />
              </Box>
            )}
          </Box>
          <BottomShadow />
        </LayoutMobile.Content>
      </LayoutMobile>
    )
  }
)
