import { TextTitleTicketsNewYearGame } from './TextTitleTicketsNewYearGame'
import { TextTitleTicketsTreasureIslandGame } from './TextTitleTicketsTreasureIslandGame'
import { TextTitleTicketsKDPayGame } from './TextTitleTicketsKDPayGame'
import { TextTitleTicketsNewYearGame2024_2025 } from './TextTitleTicketsNewYearGame2024_2025'

const TextTitleTicketsByGameId = {
  1: TextTitleTicketsNewYearGame,
  2: TextTitleTicketsTreasureIslandGame,
  3: TextTitleTicketsKDPayGame,
  4: TextTitleTicketsNewYearGame2024_2025,
}

const gameId = process.env.REACT_APP_GAME_ID
export const TextTitleTickets = TextTitleTicketsByGameId[gameId]
