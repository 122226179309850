import { sparInlineLogoPropsNewYearGame } from './sparInlineLogoPropsNewYearGame'
import { sparInlineLogoPropsTreasureIslandGame } from './sparInlineLogoPropsTreasureIslandGame'
import { sparInlineLogoPropsKDPayGame } from './sparInlineLogoPropsKDPayGame'
import { sparInlineLogoPropsNewYearGame2024_2025 } from './sparInlineLogoPropsNewYearGame2024_2025'

const sparInlineLogoPropsByGameId = {
  1: sparInlineLogoPropsNewYearGame,
  2: sparInlineLogoPropsTreasureIslandGame,
  3: sparInlineLogoPropsKDPayGame,
  4: sparInlineLogoPropsNewYearGame2024_2025,
}

const gameId = process.env.REACT_APP_GAME_ID
export const sparInlineLogoProps = sparInlineLogoPropsByGameId[gameId]
