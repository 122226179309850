import styled from 'styled-components'

export const BoardKeyPreviewWrapperNewYearGame2024_2025 = styled.div`
  position: relative;
  z-index: 20;
  width: 28%;
  max-width: 120px;
  transform: translate(0, 0);

  background-image: url(/img/newYearGame2024_2025/board_hammer_glowing.png);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;

  &:before {
    content: '';

    display: block;
    padding-top: 100%;
  }

  @media (min-width: 768px) {
    width: 112px;
  }

  @media (min-width: 1903px) {
    width: 4.2vw;
  }
`
