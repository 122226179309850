import { useMemo } from 'react'

export const useBgImageNewYearGame2024_2025 = ({
  isWideViewport,
  calendarType,
}) => {
  const bgImage = useMemo(() => {
    const bgImageFileName = isWideViewport
      ? `board-${calendarType}`
      : `board-mobile-${calendarType}`

    return `/img/newYearGame2024_2025/${bgImageFileName}.png`
  }, [isWideViewport, calendarType])

  return bgImage
}
