import { popupInfoFrontLogoPropsNewYearGame } from './popupInfoFrontLogoPropsNewYearGame'
import { popupInfoFrontLogoPropsTreasureIslandGame } from './popupInfoFrontLogoPropsTreasureIslandGame'
import { popupInfoFrontLogoPropsKDPayGame } from './popupInfoFrontLogoPropsKDPayGame'
import { popupInfoFrontLogoPropsNewYearGame2024_2025 } from './popupInfoFrontLogoPropsNewYearGame2024_2025'

const popupInfoFrontLogoPropsByGameId = {
  1: popupInfoFrontLogoPropsNewYearGame,
  2: popupInfoFrontLogoPropsTreasureIslandGame,
  3: popupInfoFrontLogoPropsKDPayGame,
  4: popupInfoFrontLogoPropsNewYearGame2024_2025,
}

const gameId = process.env.REACT_APP_GAME_ID
export const popupInfoFrontLogoProps = popupInfoFrontLogoPropsByGameId[gameId]
