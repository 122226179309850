import { TopSectionLogoWrapperNewYearGame } from './TopSectionLogoWrapperNewYearGame'
import { TopSectionLogoWrapperTreasureIslandGame } from './TopSectionLogoWrapperTreasureIslandGame'
import { TopSectionLogoWrapperKDPayGame } from './TopSectionLogoWrapperKDPayGame'
import { TopSectionLogoWrapperNewYearGame2024_2025 } from './TopSectionLogoWrapperNewYearGame2024_2025'

const TopSectionLogoWrapperByGameId = {
  1: TopSectionLogoWrapperNewYearGame,
  2: TopSectionLogoWrapperTreasureIslandGame,
  3: TopSectionLogoWrapperKDPayGame,
  4: TopSectionLogoWrapperNewYearGame2024_2025,
}

const gameId = process.env.REACT_APP_GAME_ID
export const TopSectionLogoWrapper = TopSectionLogoWrapperByGameId[gameId]
