import { KeysCarouselControlNewYearGame } from './KeysCarouselControlNewYearGame'
import { KeysCarouselControlTreasureIslandGame } from './KeysCarouselControlTreasureIslandGame'
import { KeysCarouselControlKDPayGame } from './KeysCarouselControlKDPayGame'
import { KeysCarouselControlNewYearGame2024_2025 } from './KeysCarouselControlNewYearGame2024_2025'

const KeysCarouselControlByGameId = {
  1: KeysCarouselControlNewYearGame,
  2: KeysCarouselControlTreasureIslandGame,
  3: KeysCarouselControlKDPayGame,
  4: KeysCarouselControlNewYearGame2024_2025,
}

const gameId = process.env.REACT_APP_GAME_ID
export const KeysCarouselControl = KeysCarouselControlByGameId[gameId]
