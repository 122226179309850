import { topSectionLogoPropsNewYearGame } from './topSectionLogoPropsNewYearGame'
import { topSectionLogoPropsTreasureIslandGame } from './topSectionLogoPropsTreasureIslandGame'
import { topSectionLogoPropsKDPayGame } from './topSectionLogoPropsKDPayGame'
import { topSectionLogoPropsNewYearGame2024_2025 } from './topSectionLogoPropsNewYearGame2024_2025'

const topSectionLogoPropsByGameId = {
  1: topSectionLogoPropsNewYearGame,
  2: topSectionLogoPropsTreasureIslandGame,
  3: topSectionLogoPropsKDPayGame,
  4: topSectionLogoPropsNewYearGame2024_2025,
}

const gameId = process.env.REACT_APP_GAME_ID
export const topSectionLogoProps = topSectionLogoPropsByGameId[gameId]
