import { TextTitleSponsorsNewYearGame } from './TextTitleSponsorsNewYearGame'
import { TextTitleSponsorsTreasureIslandGame } from './TextTitleSponsorsTreasureIslandGame'
import { TextTitleSponsorsKDPayGame } from './TextTitleSponsorsKDPayGame'
import { TextTitleSponsorsNewYearGame2024_2025 } from './TextTitleSponsorsNewYearGame2024_2025'

const TextTitleSponsorsByGameId = {
  1: TextTitleSponsorsNewYearGame,
  2: TextTitleSponsorsTreasureIslandGame,
  3: TextTitleSponsorsKDPayGame,
  4: TextTitleSponsorsNewYearGame2024_2025,
}

const gameId = process.env.REACT_APP_GAME_ID
export const TextTitleSponsors = TextTitleSponsorsByGameId[gameId]
