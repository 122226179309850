import { popupHasLogoConfigNewYearGame } from './popupHasLogoConfigNewYearGame'
import { popupHasLogoConfigTreasureIslandGame } from './popupHasLogoConfigTreasureIslandGame'
import { popupHasLogoConfigKDPayGame } from './popupHasLogoConfigKDPayGame'
import { popupHasLogoConfigNewYearGame2024_2025 } from './popupHasLogoConfigNewYearGame2024_2025'

const popupHasLogoConfigByGameId = {
  1: popupHasLogoConfigNewYearGame,
  2: popupHasLogoConfigTreasureIslandGame,
  3: popupHasLogoConfigKDPayGame,
  4: popupHasLogoConfigNewYearGame2024_2025,
}

const gameId = process.env.REACT_APP_GAME_ID
export const popupHasLogoConfig = popupHasLogoConfigByGameId[gameId]
