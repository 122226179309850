import { CALENDAR_ITEM_STATUS } from 'constants/calendarGifts'
import { useMemo } from 'react'

export const useGiftImgNewYearGame2024_2025 = ({ calendarItem, index }) => {
  const giftImg = useMemo(() => {
    if (calendarItem?.status === CALENDAR_ITEM_STATUS.OPEN) {
      return calendarItem?.gift?.logo_url
    }
    return `/img/newYearGame2024_2025/closed_gift_${index}.png`
  }, [index, calendarItem])

  return giftImg
}
