import { BottomShadowNewYearGame } from './BottomShadowNewYearGame'
import { BottomShadowTreasureIslandGame } from './BottomShadowTreasureIslandGame'
import { BottomShadowKDPayGame } from './BottomShadowKDPayGame'
import { BottomShadowNewYearGame2024_2025 } from './BottomShadowNewYearGame2024_2025'

export const BottomShadowByGameId = {
  1: BottomShadowNewYearGame,
  2: BottomShadowTreasureIslandGame,
  3: BottomShadowKDPayGame,
  4: BottomShadowNewYearGame2024_2025,
}

const gameId = process.env.REACT_APP_GAME_ID
export const BottomShadow = BottomShadowByGameId[gameId]
