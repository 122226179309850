import { TopSectionLogoContainerNewYearGame } from './TopSectionLogoContainerNewYearGame'
import { TopSectionLogoContainerTreasureIslandGame } from './TopSectionLogoContainerTreasureIslandGame'
import { TopSectionLogoContainerKDPayGame } from './TopSectionLogoContainerKDPayGame'
import { TopSectionLogoContainerNewYearGame2024_2025 } from './TopSectionLogoContainerNewYearGame2024_2025'

const TopSectionLogoContainerByGameId = {
  1: TopSectionLogoContainerNewYearGame,
  2: TopSectionLogoContainerTreasureIslandGame,
  3: TopSectionLogoContainerKDPayGame,
  4: TopSectionLogoContainerNewYearGame2024_2025,
}

const gameId = process.env.REACT_APP_GAME_ID
export const TopSectionLogoContainer = TopSectionLogoContainerByGameId[gameId]
