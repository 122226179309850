import { useTicketsHeightNewYearGame } from './useTicketsHeightNewYearGame'
import { useTicketsHeightTreasureIslandGame } from './useTicketsHeightTreasureIslandGame'
import { useTicketsHeightKDPayGame } from './useTicketsHeightKDPayGame'
import { useTicketsHeightNewYearGame2024_2025 } from './useTicketsHeightNewYearGame2024_2025'

const useTicketsHeightByGameId = {
  1: useTicketsHeightNewYearGame,
  2: useTicketsHeightTreasureIslandGame,
  3: useTicketsHeightKDPayGame,
  4: useTicketsHeightNewYearGame2024_2025,
}

const gameId = process.env.REACT_APP_GAME_ID
export const useTicketsHeight = useTicketsHeightByGameId[gameId]
