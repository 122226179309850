import { PopupFrontLogoNewYearGame } from './PopupFrontLogoNewYearGame'
import { PopupFrontLogoTreasureIslandGame } from './PopupFrontLogoTreasureIslandGame'
import { PopupFrontLogoKDPayGame } from './PopupFrontLogoKDPayGame'
import { PopupFrontLogoNewYearGame2024_2025 } from './PopupFrontLogoNewYearGame2024_2025'

const PopupFrontLogoByGameId = {
  1: PopupFrontLogoNewYearGame,
  2: PopupFrontLogoTreasureIslandGame,
  3: PopupFrontLogoKDPayGame,
  4: PopupFrontLogoNewYearGame2024_2025,
}

const gameId = process.env.REACT_APP_GAME_ID
export const PopupFrontLogo = PopupFrontLogoByGameId[gameId]
