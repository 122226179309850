import styled from 'styled-components'
import { Popup } from 'ui/atoms'
import { PopupInfoPanel } from './PopupInfoPanel'
import { PopupInfoFrontLogo } from './PopupInfoFrontLogo'
import { popupInfoFrontLogoProps } from './popupInfoFrontLogoProps'
import { PopupInfoClose } from './PopupInfoClose'
import { popupHasLogoConfig } from './popupHasLogoConfig'
import { PopupInfoBackLogo } from './PopupInfoBackLogo'

export const PopupInfo = ({
  children,
  isOpened,
  onClose,
  hasLogo = true,
  hasCloseBtn,
  logoVariant = 'default',
}) => (
  <Popup isOpened={isOpened} onClose={onClose}>
    <PopupInfoPanel hasLogo={hasLogo}>
      {hasLogo && popupHasLogoConfig.hasBackLogo && (
        <PopupInfoBackLogo variant={logoVariant} />
      )}
      {hasLogo && popupHasLogoConfig.hasFrontLogo && (
        <PopupInfoFrontLogo
          variant={logoVariant}
          {...popupInfoFrontLogoProps}
        />
      )}
      {hasCloseBtn && <PopupInfoClose onClick={onClose} />}

      {children}
    </PopupInfoPanel>
  </Popup>
)
