import { fontsNewYearGame } from './fontsNewYearGame'
import { fontsTreasureIslandGame } from './fontsTreasureIslandGame'
import { fontsKDPayGame } from './fontsKDPayGame'
import { fontsNewYearGame2024_2025 } from './fontsNewYearGame2024_2025'

const fontsByGameId = {
  1: fontsNewYearGame,
  2: fontsTreasureIslandGame,
  3: fontsKDPayGame,
  4: fontsNewYearGame2024_2025,
}

const gameId = process.env.REACT_APP_GAME_ID
export const fonts = fontsByGameId[gameId]
