import styled from 'styled-components'
import { LogoVariantsTreasureIslandGame } from './LogoVariantsTreasureIslandGame'

export const PopupInfoBackLogoTreasureIslandGame = styled.div`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: 377px;
  height: 288px;
  background-size: contain;
  background-repeat: no-repeat;
  z-index: -1;
  ${LogoVariantsTreasureIslandGame}
`
