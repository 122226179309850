import { PopupInfoFrontLogoNewYearGame } from './PopupInfoFrontLogoNewYearGame'
import { PopupInfoFrontLogoTreasureIslandGame } from './PopupInfoFrontLogoTreasureIslandGame'
import { PopupInfoFrontLogoKDPayGame } from './PopupInfoFrontLogoKDPayGame'
import { PopupInfoFrontLogoNewYearGame2024_2025 } from './PopupInfoFrontLogoNewYearGame2024_2025'

const PopupInfoFrontLogoByGameId = {
  1: PopupInfoFrontLogoNewYearGame,
  2: PopupInfoFrontLogoTreasureIslandGame,
  3: PopupInfoFrontLogoKDPayGame,
  4: PopupInfoFrontLogoNewYearGame2024_2025,
}

const gameId = process.env.REACT_APP_GAME_ID
export const PopupInfoFrontLogo = PopupInfoFrontLogoByGameId[gameId]
