import { PointWrapperNewYearGame } from './PointWrapperNewYearGame'
import { PointWrapperTreasureIslandGame } from './PointWrapperTreasureIslandGame'
import { PointWrapperKDPayGame } from './PointWrapperKDPayGame'
import { PointWrapperNewYearGame2024_2025 } from './PointWrapperNewYearGame2024_2025'

const PointWrapperByGameId = {
  1: PointWrapperNewYearGame,
  2: PointWrapperTreasureIslandGame,
  3: PointWrapperKDPayGame,
  4: PointWrapperNewYearGame2024_2025,
}

const gameId = process.env.REACT_APP_GAME_ID
export const PointWrapper = PointWrapperByGameId[gameId]
