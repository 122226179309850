import { useButtonsPyNewYearGame } from './useButtonsPyNewYearGame'
import { useButtonsPyTreasureIslandGame } from './useButtonsPyTreasureIslandGame'
import { useButtonsPyKDPayGame } from './useButtonsPyKDPayGame'
import { useButtonsPyNewYearGame2024_2025 } from './useButtonsPyNewYearGame2024_2025'

const useButtonsPyByGameId = {
  1: useButtonsPyNewYearGame,
  2: useButtonsPyTreasureIslandGame,
  3: useButtonsPyKDPayGame,
  4: useButtonsPyNewYearGame2024_2025,
}

const gameId = process.env.REACT_APP_GAME_ID
export const useButtonsPy = useButtonsPyByGameId[gameId]
