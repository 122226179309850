import { contentTextPropsNewYearGame } from './contentTextPropsNewYearGame'
import { contentTextPropsTreasureIslandGame } from './contentTextPropsTreasureIslandGame'
import { contentTextPropsKDPayGame } from './contentTextPropsKDPayGame'
import { contentTextPropsNewYearGame2024_2025 } from './contentTextPropsNewYearGame2024_2025'

const contentTextPropsByGameId = {
  1: contentTextPropsNewYearGame,
  2: contentTextPropsTreasureIslandGame,
  3: contentTextPropsKDPayGame,
  4: contentTextPropsNewYearGame2024_2025,
}

const gameId = process.env.REACT_APP_GAME_ID
export const contentTextProps = contentTextPropsByGameId[gameId]
