import { useCallback, useState } from 'react'

import { useWindowResize } from 'hooks/useWindowResize'
import { config } from 'config'

const heightBreakpointPx = 692

export const useHeadingTextNewYearGame2024_2025 = () => {
  const [windowHeight, setWindowHeight] = useState(window.innerHeight)

  const handleWindowResize = useCallback(() => {
    setWindowHeight(window.innerHeight)
  }, [])

  useWindowResize(handleWindowResize)

  let headingText = config.texts.homePage.headingMobile
  if (heightBreakpointPx >= windowHeight) {
    headingText = config.texts.homePage.headingMobileShorter
  }

  return headingText
}
