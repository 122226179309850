import { logoFooterMobileWrapperPropsNewYearGame } from './logoFooterMobileWrapperPropsNewYearGame'
import { logoFooterMobileWrapperPropsTreasureIslandGame } from './logoFooterMobileWrapperPropsTreasureIslandGame'
import { logoFooterMobileWrapperPropsKDPayGame } from './logoFooterMobileWrapperPropsKDPayGame'
import { logoFooterMobileWrapperPropsNewYearGame2024_2025 } from './logoFooterMobileWrapperPropsNewYearGame2024_2025'

const logoFooterMobileWrapperPropsByGameId = {
  1: logoFooterMobileWrapperPropsNewYearGame,
  2: logoFooterMobileWrapperPropsTreasureIslandGame,
  3: logoFooterMobileWrapperPropsKDPayGame,
  4: logoFooterMobileWrapperPropsNewYearGame2024_2025,
}

const gameId = process.env.REACT_APP_GAME_ID
export const logoFooterMobileWrapperProps =
  logoFooterMobileWrapperPropsByGameId[gameId]
