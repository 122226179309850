import { congratulationsTextPropsNewYearGame } from './congratulationsTextPropsNewYearGame'
import { congratulationsTextPropsTreasureIslandGame } from './congratulationsTextPropsTreasureIslandGame'
import { congratulationsTextPropsKDPayGame } from './congratulationsTextPropsKDPayGame'
import { congratulationsTextPropsNewYearGame2024_2025 } from './congratulationsTextPropsNewYearGame2024_2025'

const congratulationsTextPropsByGameId = {
  1: congratulationsTextPropsNewYearGame,
  2: congratulationsTextPropsTreasureIslandGame,
  3: congratulationsTextPropsKDPayGame,
  4: congratulationsTextPropsNewYearGame2024_2025,
}

const gameId = process.env.REACT_APP_GAME_ID
export const congratulationsTextProps = congratulationsTextPropsByGameId[gameId]
