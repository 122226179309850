import { raffleDateTextPropsNewYearGame } from './raffleDateTextPropsNewYearGame'
import { raffleDateTextPropsTreasureIslandGame } from './raffleDateTextPropsTreasureIslandGame'
import { raffleDateTextPropsKDPayGame } from './raffleDateTextPropsKDPayGame'
import { raffleDateTextPropsNewYearGame2024_2025 } from './raffleDateTextPropsNewYearGame2024_2025'

const raffleDateTextPropsByGameId = {
  1: raffleDateTextPropsNewYearGame,
  2: raffleDateTextPropsTreasureIslandGame,
  3: raffleDateTextPropsKDPayGame,
  4: raffleDateTextPropsNewYearGame2024_2025,
}

const gameId = process.env.REACT_APP_GAME_ID
export const raffleDateTextProps = raffleDateTextPropsByGameId[gameId]
