import { display } from '@xstyled/styled-components'

export const logoFooterMobileWrapperPropsNewYearGame2024_2025 =
  isDisplayLogoFooter => {
    return isDisplayLogoFooter
      ? {
          position: 'absolute',
          bottom: '0',
          left: '0',
          right: '0',
        }
      : {
          display: 'none',
        }
  }
