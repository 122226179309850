import * as ticketsMobileConstantsNewYearGame from './ticketsMobileConstantsNewYearGame'
import * as ticketsMobileConstantsTreasureIslandGame from './ticketsMobileConstantsTreasureIslandGame'
import * as ticketsMobileConstantsKDPayGame from './ticketsMobileConstantsKDPayGame'
import * as ticketsMobileConstantsNewYearGame2024_2025 from './ticketsMobileConstantsNewYearGame2024_2025'

const ticketsMobileContantsByGameId = {
  1: ticketsMobileConstantsNewYearGame,
  2: ticketsMobileConstantsTreasureIslandGame,
  3: ticketsMobileConstantsKDPayGame,
  4: ticketsMobileConstantsNewYearGame2024_2025,
}

const gameId = process.env.REACT_APP_GAME_ID
export const ticketsMobileContants = ticketsMobileContantsByGameId[gameId]
