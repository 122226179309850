import { useCalendarGiftPositionsNewYearGame } from './useCalendarGiftPositionsNewYearGame'
import { useCalendarGiftPositionsTreasureIslandGame } from './useCalendarGiftPositionsTreasureIslandGame'
import { useCalendarGiftPositionsKDPayGame } from './useCalendarGiftPositionsKDPayGame'
import { useCalendarGiftPositionsNewYearGame2024_2025 } from './useCalendarGiftPositionsNewYearGame2024_2025'

const useCalendarGiftPositionsByGameId = {
  1: useCalendarGiftPositionsNewYearGame,
  2: useCalendarGiftPositionsTreasureIslandGame,
  3: useCalendarGiftPositionsKDPayGame,
  4: useCalendarGiftPositionsNewYearGame2024_2025,
}

const gameId = process.env.REACT_APP_GAME_ID
export const useCalendarGiftPositions = useCalendarGiftPositionsByGameId[gameId]
