import React from 'react'
import styled from 'styled-components'

import { Box, Flex } from 'ui/atoms'
import { LayoutMobile } from 'ui/layout'
import { config } from 'config'
import { TextTitleRaffleWinner } from './TextTitleRaffleWinner'
import { RaffleWinnerItemText } from './RaffleWinnerItemText'
import { raffleWinnerItemImgProps } from './raffleWinnerItemImgProps'

const RaffleWinnerItemImg = styled.img`
  width: 100%;
  margin: 0 auto;
`

const RaffleWinnerItem = ({ img, text }) => {
  return (
    <Flex flexDirection="column" alignItems="center" mb="20px">
      <RaffleWinnerItemText>{text}</RaffleWinnerItemText>
      <Box borderRadius="10px" overflow="hidden" maxWidth="700px">
        <RaffleWinnerItemImg src={img} alt="" />
      </Box>
    </Flex>
  )
}

export const RaffleWinnersMobile = React.forwardRef(
  ({ tickets, isLoading, hasError, onRetry, isWideViewport }, ref) => {
    return (
      <LayoutMobile
        ref={ref}
        {...config.backgrounds.RAFFLE_WINNERS_MOBILE_BACKGROUND}
        isRaffleWinnersPage={true}
      >
        <LayoutMobile.Header mb="16px">
          <TextTitleRaffleWinner>Победители</TextTitleRaffleWinner>
        </LayoutMobile.Header>

        <LayoutMobile.Content isRaffleWinnersPage={true}>
          <RaffleWinnerItem
            img="/img/newYearGame2024_2025/raffle_winners_from_25_11_2024_to_01_12_2024.jpg"
            text="25 ноября - 1 декабря"
          />
        </LayoutMobile.Content>
      </LayoutMobile>
    )
  }
)
