import styled from 'styled-components'

export const PopupInfoFrontLogoNewYearGame2024_2025 = styled.div`
  position: absolute;
  top: -144px;
  left: 50%;
  transform: translateX(-50%);
  width: 174px;
  height: 166px;
  background-image: url(/img/newYearGame2024_2025/basic_popup_heading_front.png);
  background-position: center center;
  background-size: contain;
  background-repeat: no-repeat;
`
