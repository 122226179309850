import { TextTitleHomeNewYearGame } from './TextTitleHomeNewYearGame'
import { TextTitleHomeTreasureIslandGame } from './TextTitleHomeTreasureIslandGame'
import { TextTitleHomeKDPayGame } from './TextTitleHomeKDPayGame'
import { TextTitleHomeNewYearGame2024_2025 } from './TextTitleHomeNewYearGame2024_2025'

const TextTitleHomeByGameId = {
  1: TextTitleHomeNewYearGame,
  2: TextTitleHomeTreasureIslandGame,
  3: TextTitleHomeKDPayGame,
  4: TextTitleHomeNewYearGame2024_2025,
}

const gameId = process.env.REACT_APP_GAME_ID
export const TextTitleHome = TextTitleHomeByGameId[gameId]
