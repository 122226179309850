import theme from 'ui/settings/theme'

export const toCalendarsButtonPropsKDPayGame = {
  as: 'span',
  variant: 'primary',
  w: '100%',
  px: { _: '16px', xs: '20px' },
  py: { _: '12px', xs: '16px' },
  mb: '16px',
  fontSize: { _: '16px', xs: '20px' },
  boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.15)',
}
