import { ButtonVariantsNewYearGame } from './ButtonVariantsNewYearGame'
import { ButtonVariantsTreasureIslandGame } from './ButtonVariantsTreasureIslandGame'
import { ButtonVariantsKDPayGame } from './ButtonVariantsKDPayGame'
import { ButtonVariantsNewYearGame2024_2025 } from './ButtonVariantsNewYearGame2024_2025'

const ButtonVariantsByGameId = {
  1: ButtonVariantsNewYearGame,
  2: ButtonVariantsTreasureIslandGame,
  3: ButtonVariantsKDPayGame,
  4: ButtonVariantsNewYearGame2024_2025,
}

const gameId = process.env.REACT_APP_GAME_ID
export const ButtonVariants = ButtonVariantsByGameId[gameId]
