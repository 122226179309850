import theme from 'ui/settings/theme'

export const myTicketsButtonPropsNewYearGame2024_2025 = {
  as: 'span',
  variant: 'default-brighter',
  w: '100%',
  px: { _: '16px', xs: '20px' },
  py: { _: '12px', xs: '16px' },
  fontSize: { _: '16px', xs: '20px' },
  boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.15)',
}
