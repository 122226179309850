import { TextTitleCalendarsNewYearGame } from './TextTitleCalendarsNewYearGame'
import { TextTitleCalendarsTreasureIslandGame } from './TextTitleCalendarsTreasureIslandGame'
import { TextTitleCalendarsKDPayGame } from './TextTitleCalendarsKDPayGame'
import { TextTitleCalendarsNewYearGame2024_2025 } from './TextTitleCalendarsNewYearGame2024_2025'

const TextTitleCalendarsByGameId = {
  1: TextTitleCalendarsNewYearGame,
  2: TextTitleCalendarsTreasureIslandGame,
  3: TextTitleCalendarsKDPayGame,
  4: TextTitleCalendarsNewYearGame2024_2025,
}

const gameId = process.env.REACT_APP_GAME_ID
export const TextTitleCalendars = TextTitleCalendarsByGameId[gameId]
