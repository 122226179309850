import styled from 'styled-components'
import useMatchMedia from 'react-use-match-media'
import { useLocation, matchPath } from 'react-router-dom'

import { Box, Text } from 'ui/atoms'
import { PATHS } from 'routing/paths'
import { titleTextProps } from './titleTextProps'
import { CaptionText } from './CaptionText'
import { subTitleTextProps } from './subTitleTextProps'

const NoticeItemDisplay = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 84px;
  padding: 4px;
  border-radius: 15px;
  background-color: #ffffff;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 12px;
  box-shadow: 0.886243px 2.65873px 18.61111px 0px #10575e4f;
`

export const NoticeItem = ({
  variant,
  size,
  title,
  subtitle,
  caption,
  ...props
}) => {
  const isWideViewport = useMatchMedia('(min-width: 1200px)')
  const { pathname } = useLocation()
  const isHome = !!matchPath({ path: pathname, exact: true }, PATHS.HOME)

  return (
    <Box mb="40px" position="relative" {...props}>
      <NoticeItemDisplay variant={variant} size={size}>
        <Text {...titleTextProps}>{title}</Text>
        {subtitle && <Text {...subTitleTextProps}>{subtitle}</Text>}
      </NoticeItemDisplay>

      <CaptionText
        fontSize={{ _: '13px', lg: '17px' }}
        fontWeight="700"
        lineHeight="100%"
        textAlign="center"
        isHome={isHome}
        isWideViewport={isWideViewport}
      >
        {caption}
      </CaptionText>
    </Box>
  )
}
