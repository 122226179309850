import { KeyImgNewYearGame } from './KeyImgNewYearGame'
import { KeyImgTreasureIslandGame } from './KeyImgTreasureIslandGame'
import { KeyImgKDPayGame } from './KeyImgKDPayGame'
import { KeyImgNewYearGame2024_2025 } from './KeyImgNewYearGame2024_2025'

const KeyImgByGameId = {
  1: KeyImgNewYearGame,
  2: KeyImgTreasureIslandGame,
  3: KeyImgKDPayGame,
  4: KeyImgNewYearGame2024_2025,
}

const gameId = process.env.REACT_APP_GAME_ID
export const KeyImg = KeyImgByGameId[gameId]
