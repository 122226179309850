import { subTitleTextPropsNewYearGame } from './subTitleTextPropsNewYearGame'
import { subTitleTextPropsTreasureIslandGame } from './subTitleTextPropsTreasureIslandGame'
import { subTitleTextPropsKDPayGame } from './subTitleTextPropsKDPayGame'
import { subTitleTextPropsNewYearGame2024_2025 } from './subTitleTextPropsNewYearGame2024_2025'

const subTitleTextPropsByGameId = {
  1: subTitleTextPropsNewYearGame,
  2: subTitleTextPropsTreasureIslandGame,
  3: subTitleTextPropsKDPayGame,
  4: subTitleTextPropsNewYearGame2024_2025,
}

const gameId = process.env.REACT_APP_GAME_ID
export const subTitleTextProps = subTitleTextPropsByGameId[gameId]
