import { TicketListContentNewYearGame } from './TicketListContentNewYearGame'
import { TicketListContentTreasureIslandGame } from './TicketListContentTreasureIslandGame'
import { TicketListContentKDPayGame } from './TicketListContentKDPayGame'
import { TicketListContentNewYearGame2024_2025 } from './TicketListContentNewYearGame2024_2025'

const TicketListContentByGameId = {
  1: TicketListContentNewYearGame,
  2: TicketListContentTreasureIslandGame,
  3: TicketListContentKDPayGame,
  4: TicketListContentNewYearGame2024_2025,
}

const gameId = process.env.REACT_APP_GAME_ID
export const TicketListContent = TicketListContentByGameId[gameId]
