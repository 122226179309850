import { keysPropsNewYearGame } from './keysPropsNewYearGame'
import { keysPropsTreasureIslandGame } from './keysPropsTreasureIslandGame'
import { keysPropsKDPayGame } from './keysPropsKDPayGame'
import { keysPropsNewYearGame2024_2025 } from './keysPropsNewYearGame2024_2025'

const keysPropsByGameId = {
  1: keysPropsNewYearGame,
  2: keysPropsTreasureIslandGame,
  3: keysPropsKDPayGame,
  4: keysPropsNewYearGame2024_2025,
}

const gameId = process.env.REACT_APP_GAME_ID
export const keysProps = keysPropsByGameId[gameId]
