import { ticketsInfoWrapperPropsDesktopNewYearGame } from './ticketsInfoWrapperPropsDesktopNewYearGame'
import { ticketsInfoWrapperPropsDesktopTreasureIslandGame } from './ticketsInfoWrapperPropsDesktopTreasureIslandGame'
import { ticketsInfoWrapperPropsDesktopKDPayGame } from './ticketsInfoWrapperPropsDesktopKDPayGame'
import { ticketsInfoWrapperPropsDesktopNewYearGame2024_2025 } from './ticketsInfoWrapperPropsDesktopNewYearGame2024_2025'

const ticketsInfoWrapperPropsDesktopByGameId = {
  1: ticketsInfoWrapperPropsDesktopNewYearGame,
  2: ticketsInfoWrapperPropsDesktopTreasureIslandGame,
  3: ticketsInfoWrapperPropsDesktopKDPayGame,
  4: ticketsInfoWrapperPropsDesktopNewYearGame2024_2025,
}

const gameId = process.env.REACT_APP_GAME_ID
export const ticketsInfoWrapperPropsDesktop =
  ticketsInfoWrapperPropsDesktopByGameId[gameId]
