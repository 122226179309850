import { PopupAlertPanelNewYearGame } from './PopupAlertPanelNewYearGame'
import { PopupAlertPanelTreasureIslandGame } from './PopupAlertPanelTreasureIslandGame'
import { PopupAlertPanelKDPayGame } from './PopupAlertPanelKDPayGame'
import { PopupAlertPanelNewYearGame2024_2025 } from './PopupAlertPanelNewYearGame2024_2025'

const PopupAlertPanelByGameId = {
  1: PopupAlertPanelNewYearGame,
  2: PopupAlertPanelTreasureIslandGame,
  3: PopupAlertPanelKDPayGame,
  4: PopupAlertPanelNewYearGame2024_2025,
}

const gameId = process.env.REACT_APP_GAME_ID
export const PopupAlertPanel = PopupAlertPanelByGameId[gameId]
