import { familyLogoPropsNewYearGame } from './familyLogoPropsNewYearGame'
import { familyLogoPropsTreasureIslandGame } from './familyLogoPropsTreasureIslandGame'
import { familyLogoPropsKDPayGame } from './familyLogoPropsKDPayGame'
import { familyLogoPropsNewYearGame2024_2025 } from './familyLogoPropsNewYearGame2024_2025'

const familyLogoPropsByGameId = {
  1: familyLogoPropsNewYearGame,
  2: familyLogoPropsTreasureIslandGame,
  3: familyLogoPropsKDPayGame,
  4: familyLogoPropsNewYearGame2024_2025,
}

const gameId = process.env.REACT_APP_GAME_ID
export const familyLogoProps = familyLogoPropsByGameId[gameId]
