import { keysInfoWrapperPropsDesktopNewYearGame } from './keysInfoWrapperPropsDesktopNewYearGame'
import { keysInfoWrapperPropsDesktopTreasureIslandGame } from './keysInfoWrapperPropsDesktopTreasureIslandGame'
import { keysInfoWrapperPropsDesktopKDPayGame } from './keysInfoWrapperPropsDesktopKDPayGame'
import { keysInfoWrapperPropsDesktopNewYearGame2024_2025 } from './keysInfoWrapperPropsDesktopNewYearGame2024_2025'

const keysInfoWrapperPropsDesktopByGameId = {
  1: keysInfoWrapperPropsDesktopNewYearGame,
  2: keysInfoWrapperPropsDesktopTreasureIslandGame,
  3: keysInfoWrapperPropsDesktopKDPayGame,
  4: keysInfoWrapperPropsDesktopNewYearGame2024_2025,
}

const gameId = process.env.REACT_APP_GAME_ID
export const keysInfoWrapperPropsDesktop =
  keysInfoWrapperPropsDesktopByGameId[gameId]
