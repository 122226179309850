import { TextPageTitleNewYearGame } from './TextPageTitleNewYearGame'
import { TextPageTitleTreasureIslandGame } from './TextPageTitleTreasureIslandGame'
import { TextPageTitleKDPayGame } from './TextPageTitleKDPayGame'
import { TextPageTitleNewYearGame2024_2025 } from './TextPageTitleNewYearGame2024_2025'

const TextPageTitleByGameId = {
  1: TextPageTitleNewYearGame,
  2: TextPageTitleTreasureIslandGame,
  3: TextPageTitleKDPayGame,
  4: TextPageTitleNewYearGame2024_2025,
}

const gameId = process.env.REACT_APP_GAME_ID
export const TextPageTitle = TextPageTitleByGameId[gameId]
