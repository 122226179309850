import { titleTextPropsNewYearGame } from './titleTextPropsNewYearGame'
import { titleTextPropsTreasureIslandGame } from './titleTextPropsTreasureIslandGame'
import { titleTextPropsKDPayGame } from './titleTextPropsKDPayGame'
import { titleTextPropsNewYearGame2024_2025 } from './titleTextPropsNewYearGame2024_2025'

const titleTextPropsByGameId = {
  1: titleTextPropsNewYearGame,
  2: titleTextPropsTreasureIslandGame,
  3: titleTextPropsKDPayGame,
  4: titleTextPropsNewYearGame2024_2025,
}

const gameId = process.env.REACT_APP_GAME_ID
export const titleTextProps = titleTextPropsByGameId[gameId]
