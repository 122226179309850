import { useBgImageNewYearGame } from './useBgImageNewYearGame'
import { useBgImageTreasureIslandGame } from './useBgImageTreasureIslandGame'
import { useBgImageKDPayGame } from './useBgImageKDPayGame'
import { useBgImageNewYearGame2024_2025 } from './useBgImageNewYearGame2024_2025'

const useBgImageByGameId = {
  1: useBgImageNewYearGame,
  2: useBgImageTreasureIslandGame,
  3: useBgImageKDPayGame,
  4: useBgImageNewYearGame2024_2025,
}

const gameId = process.env.REACT_APP_GAME_ID
export const useBgImage = useBgImageByGameId[gameId]
