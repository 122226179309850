import { toCalendarsButtonPropsNewYearGame } from './toCalendarsButtonPropsNewYearGame'
import { toCalendarsButtonPropsTreasureIslandGame } from './toCalendarsButtonPropsTreasureIslandGame'
import { toCalendarsButtonPropsKDPayGame } from './toCalendarsButtonPropsKDPayGame'
import { toCalendarsButtonPropsNewYearGame2024_2025 } from './toCalendarsButtonPropsNewYearGame2024_2025'

const toCalendarsButtonPropsByGameId = {
  1: toCalendarsButtonPropsNewYearGame,
  2: toCalendarsButtonPropsTreasureIslandGame,
  3: toCalendarsButtonPropsKDPayGame,
  4: toCalendarsButtonPropsNewYearGame2024_2025,
}

const gameId = process.env.REACT_APP_GAME_ID
export const toCalendarsButtonProps = toCalendarsButtonPropsByGameId[gameId]
