import { TicketListItemNewYearGame } from './TicketListItemNewYearGame'
import { TicketListItemTreasureIslandGame } from './TicketListItemTreasureIslandGame'
import { TicketListItemKDPayGame } from './TicketListItemKDPayGame'
import { TicketListItemNewYearGame2024_2025 } from './TicketListItemNewYearGame2024_2025'

const TicketListItemByGameId = {
  1: TicketListItemNewYearGame,
  2: TicketListItemTreasureIslandGame,
  3: TicketListItemKDPayGame,
  4: TicketListItemNewYearGame2024_2025,
}

const gameId = process.env.REACT_APP_GAME_ID
export const TicketListItem = TicketListItemByGameId[gameId]
