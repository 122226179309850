import { useCallback, useMemo, useState } from 'react'

import { useWindowResize } from 'hooks/useWindowResize'

const heightBreakpointPx = 841

export const useLogoBackgroundSizeNewYearGame2024_2025 = () => {
  const [windowHeight, setWindowHeight] = useState(window.innerHeight)

  const handleWindowResize = useCallback(() => {
    setWindowHeight(window.innerHeight)
  }, [])

  useWindowResize(handleWindowResize)

  let backgroundSize = null
  if (heightBreakpointPx >= windowHeight) {
    backgroundSize = 'auto 30%, 1200px auto'
  }

  return backgroundSize ? { backgroundSize } : null
}
