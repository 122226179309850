import styled, { css } from 'styled-components'

export const BoardSceneWrapperNewYearGame2024_2025 = styled.div`
  ${({ isWideViewport, calendarType }) => {
    if (isWideViewport) {
      return css`
        position: relative;
      `
    } else {
      if (calendarType === 1) {
        return css`
          position: absolute;
          left: 50%;
          transform: translate(-50%);
          bottom: -48px;
        `
      } else if (calendarType === 2) {
        return css`
          position: absolute;
          left: 50%;
          transform: translate(-50%);
          bottom: -24px;
        `
      } else {
        return css`
          position: absolute;
          left: 50%;
          transform: translate(-50%);
          bottom: -17px;
        `
      }
    }
  }};
  display: flex;
  align-items: flex-end;
  justify-content: center;
`
