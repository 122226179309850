import { contentSectionTopPropsNewYearGame } from './contentSectionTopPropsNewYearGame'
import { contentSectionTopPropsTreasureIslandGame } from './contentSectionTopPropsTreasureIslandGame'
import { contentSectionTopPropsKDPayGame } from './contentSectionTopPropsKDPayGame'
import { contentSectionTopPropsNewYearGame2024_2025 } from './contentSectionTopPropsNewYearGame2024_2025'

const contentSectionTopPropsByGameId = {
  1: contentSectionTopPropsNewYearGame,
  2: contentSectionTopPropsTreasureIslandGame,
  3: contentSectionTopPropsKDPayGame,
  4: contentSectionTopPropsNewYearGame2024_2025,
}

const gameId = process.env.REACT_APP_GAME_ID
export const contentSectionTopProps = contentSectionTopPropsByGameId[gameId]
