import { myTicketsButtonPropsNewYearGame } from './myTicketsButtonPropsNewYearGame'
import { myTicketsButtonPropsTreasureIslandGame } from './myTicketsButtonPropsTreasureIslandGame'
import { myTicketsButtonPropsKDPayGame } from './myTicketsButtonPropsKDPayGame'
import { myTicketsButtonPropsNewYearGame2024_2025 } from './myTicketsButtonPropsNewYearGame2024_2025'

const myTicketsButtonPropsByGameId = {
  1: myTicketsButtonPropsNewYearGame,
  2: myTicketsButtonPropsTreasureIslandGame,
  3: myTicketsButtonPropsKDPayGame,
  4: myTicketsButtonPropsNewYearGame2024_2025,
}

const gameId = process.env.REACT_APP_GAME_ID
export const myTicketsButtonProps = myTicketsButtonPropsByGameId[gameId]
