import styled from 'styled-components'

export const PopupBackLogoNewYearGame2024_2025 = styled.div`
  position: absolute;
  top: -168px;
  left: 50%;
  transform: translateX(-50%);
  width: 390px;
  height: 270px;
  background-image: url(/img/newYearGame2024_2025/discount_and_promocode_popup_heading_back.png);
  background-size: contain;
  background-repeat: no-repeat;
  z-index: -1;
`
