import { ticketValueTextPropsNewYearGame } from './ticketValueTextPropsNewYearGame'
import { ticketValueTextPropsTreasureIslandGame } from './ticketValueTextPropsTreasureIslandGame'
import { ticketValueTextPropsKDPayGame } from './ticketValueTextPropsKDPayGame'
import { ticketValueTextPropsNewYearGame2024_2025 } from './ticketValueTextPropsNewYearGame2024_2025'

const ticketValueTextPropsByGameId = {
  1: ticketValueTextPropsNewYearGame,
  2: ticketValueTextPropsTreasureIslandGame,
  3: ticketValueTextPropsKDPayGame,
  4: ticketValueTextPropsNewYearGame2024_2025,
}

const gameId = process.env.REACT_APP_GAME_ID
export const ticketValueTextProps = ticketValueTextPropsByGameId[gameId]
