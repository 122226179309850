import styled from 'styled-components'

export const CalendarListItemIndicatorNewYearGame2024_2025 = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${({ isWideViewport }) => (isWideViewport ? '80px' : '58px')};
  height: ${({ isWideViewport }) => (isWideViewport ? '56px' : '40px')};
  border-radius: ${({ isWideViewport }) => (isWideViewport ? '18px' : '12px')};
  background-image: ${({ isCompleted }) => {
    return isCompleted
      ? 'linear-gradient(180deg, #46BF94 0%, #20BBB0 100%)'
      : ''
  }};
  background-color: ${({ isCompleted, theme }) => {
    return isCompleted ? '' : theme.colors.red[5]
  }};
  box-shadow: ${({ isCompleted }) => {
    return isCompleted
      ? '0px -3px 0px 0px #00000040 inset'
      : '0px -3px 0px 0px #00000040 inset'
  }};
`
