import { PopupInfoBackLogoNewYearGame } from './PopupInfoBackLogoNewYearGame'
import { PopupInfoBackLogoTreasureIslandGame } from './PopupInfoBackLogoTreasureIslandGame'
import { PopupInfoBackLogoKDPayGame } from './PopupInfoBackLogoKDPayGame'
import { PopupInfoBackLogoNewYearGame2024_2025 } from './PopupInfoBackLogoNewYearGame2024_2025'

const PopupInfoBackLogoByGameId = {
  1: PopupInfoBackLogoNewYearGame,
  2: PopupInfoBackLogoTreasureIslandGame,
  3: PopupInfoBackLogoKDPayGame,
  4: PopupInfoBackLogoNewYearGame2024_2025,
}

const gameId = process.env.REACT_APP_GAME_ID
export const PopupInfoBackLogo = PopupInfoBackLogoByGameId[gameId]
