import styled from 'styled-components'

export const TicketListItemNewYearGame2024_2025 = styled.li`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 107px;
  padding: 8px 8px 8px 16px;
  box-shadow: 0.886243px 2.6587303px 18.61111px 0px #10575e4f;
  background-image: ${({ $isExpired }) =>
    $isExpired ? null : 'linear-gradient(180deg, #FFD77B 0%, #FFCC58 100%)'};

  background-color: ${({ $isExpired }) => ($isExpired ? '#F3F4F4' : null)};

  margin-bottom: 12px;
  z-index: 1;

  &:before {
    content: '';
    position: absolute;
    left: -19px;
    top: 0;
    height: 107px;
    width: 20px;
    background-image: ${({ $isExpired }) =>
      $isExpired
        ? 'url(/img/newYearGame2024_2025/ticket_expired_left_border.png)'
        : 'url(/img/newYearGame2024_2025/ticket_active_left_border.png)'};
    background-position: left 0 bottom 0;
    background-repeat: no-repeat;
    background-size: contain;
    z-index: 2;
  }

  &:after {
    content: '';
    position: absolute;
    right: -19px;
    top: 0;
    height: 107px;
    width: 20px;
    background-image: ${({ $isExpired }) =>
      $isExpired
        ? 'url(/img/newYearGame2024_2025/ticket_expired_right_border.png)'
        : 'url(/img/newYearGame2024_2025/ticket_active_right_border.png)'};
    background-position: left 0 bottom 0;
    background-repeat: no-repeat;
    background-size: contain;
    z-index: 2;
  }

  &:last-child {
    margin-bottom: 0;
  }

  @media (min-width: 1200px) {
    min-height: 80px;
    padding: 12px 14px 12px 20px;
  }
`
