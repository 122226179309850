export const RAFFLES_REG_TERM = [
  {
    from: '2024-11-25 10:00',
    to: '2024-12-01 23:59',
    raffleDate: '2024-12-03 19:00',
    raffle: { day: 3, month: 'декабря' },
  },
  {
    from: '2024-12-02 00:00',
    to: '2024-12-08 23:59',
    raffleDate: '2024-12-10 19:00',
    raffle: { day: 10, month: 'декабря' },
  },
  {
    from: '2024-12-09 00:00',
    to: '2024-12-15 23:59',
    raffleDate: '2024-12-17 19:00',
    raffle: { day: 17, month: 'декабря' },
  },
  {
    from: '2024-12-16 00:00',
    to: '2024-12-22 23:59',
    raffleDate: '2024-12-24 19:00',
    raffle: { day: 24, month: 'декабря' },
  },
  {
    from: '2024-12-23 00:00',
    to: '2024-12-29 23:59',
    raffleDate: '2025-01-03 19:00',
    raffle: { day: 3, month: 'января' },
  },
  {
    from: '2024-12-30 00:00',
    to: '2025-01-05 23:59',
    raffleDate: '2025-01-08 19:00',
    raffle: { day: 8, month: 'января' },
  },
  {
    from: '2025-01-06 00:00',
    to: '2025-01-12 23:59',
    raffleDate: '2025-01-14 19:00',
    raffle: { day: 14, month: 'января' },
  },
  {
    from: '2025-01-13 00:00',
    to: '2025-01-19 23:59',
    raffleDate: '2025-01-21 19:00',
    raffle: { day: 21, month: 'января' },
  },
  {
    from: '2025-01-20 00:00',
    to: '2025-01-26 23:59',
    raffleDate: '2025-01-28 19:00',
    raffle: { day: 28, month: 'января' },
  },
  {
    from: '2025-01-27 00:00',
    to: '2025-02-04 23:59',
    raffleDate: '2025-02-04 19:00',
    raffle: { day: 4, month: 'февраля' },
  },
]
