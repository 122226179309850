import themeNewYearGame from './themeNewYearGame'
import themeTreasureIslandGame from './themeTreasureIslandGame'
import themeKDPayGame from './themeKDPayGame'
import themeNewYearGame2024_2025 from './themeNewYearGame2024_2025'

const themeByGameId = {
  1: themeNewYearGame,
  2: themeTreasureIslandGame,
  3: themeKDPayGame,
  4: themeNewYearGame2024_2025,
}

const gameId = process.env.REACT_APP_GAME_ID
const theme = themeByGameId[gameId]

export default theme
