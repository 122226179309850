import { TextTitleRaffleWinnerNewYearGame } from './TextTitleRaffleWinnerNewYearGame'
import { TextTitleRaffleWinnerTreasureIslandGame } from './TextTitleRaffleWinnerTreasureIslandGame'
import { TextTitleRaffleWinnerKDPayGame } from './TextTitleRaffleWinnerKDPayGame'
import { TextTitleRaffleWinnerNewYearGame2024_2025 } from './TextTitleRaffleWinnerNewYearGame2024_2025'

const TextTitleRaffleWinnerByGameId = {
  1: TextTitleRaffleWinnerNewYearGame,
  2: TextTitleRaffleWinnerTreasureIslandGame,
  3: TextTitleRaffleWinnerKDPayGame,
  4: TextTitleRaffleWinnerNewYearGame2024_2025,
}

const gameId = process.env.REACT_APP_GAME_ID
export const TextTitleRaffleWinner = TextTitleRaffleWinnerByGameId[gameId]
