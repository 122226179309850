import { PopupBackLogoNewYearGame } from './PopupBackLogoNewYearGame'
import { PopupBackLogoTreasureIslandGame } from './PopupBackLogoTreasureIslandGame'
import { PopupBackLogoKDPayGame } from './PopupBackLogoKDPayGame'
import { PopupBackLogoNewYearGame2024_2025 } from './PopupBackLogoNewYearGame2024_2025'

const PopupBackLogoByGameId = {
  1: PopupBackLogoNewYearGame,
  2: PopupBackLogoTreasureIslandGame,
  3: PopupBackLogoKDPayGame,
  4: PopupBackLogoNewYearGame2024_2025,
}

const gameId = process.env.REACT_APP_GAME_ID
export const PopupBackLogo = PopupBackLogoByGameId[gameId]
