import { boardPreloaderPropsNewYearGame } from './boardPreloaderPropsNewYearGame'
import { boardPreloaderPropsTreasureIslandGame } from './boardPreloaderPropsTreasureIslandGame'
import { boardPreloaderPropsKDPayGame } from './boardPreloaderPropsKDPayGame'
import { boardPreloaderPropsNewYearGame2024_2025 } from './boardPreloaderPropsNewYearGame2024_2025'

const boardPreloaderPropsByGameId = {
  1: boardPreloaderPropsNewYearGame,
  2: boardPreloaderPropsTreasureIslandGame,
  3: boardPreloaderPropsKDPayGame,
  4: boardPreloaderPropsNewYearGame2024_2025,
}

const gameId = process.env.REACT_APP_GAME_ID
export const boardPreloaderProps = boardPreloaderPropsByGameId[gameId]
