import { newYearGameConfig } from './newYearGameConfig'
import { treasureIslandGameConfig } from './treasureIslandGameConfig'
import { kdPayGameConfig } from './kdPayGameConfig'
import { newYearGame2024_2025Config } from './newYearGame2024_2025Config'

export const configs = {
  1: newYearGameConfig,
  2: treasureIslandGameConfig,
  3: kdPayGameConfig,
  4: newYearGame2024_2025Config,
}

const gameId = process.env.REACT_APP_GAME_ID
export const config = configs[gameId]
