import { ticketValuePropsNewYearGame } from './ticketValuePropsNewYearGame'
import { ticketValuePropsTreasureIslandGame } from './ticketValuePropsTreasureIslandGame'
import { ticketValuePropsKDPayGame } from './ticketValuePropsKDPayGame'
import { ticketValuePropsNewYearGame2024_2025 } from './ticketValuePropsNewYearGame2024_2025'

const ticketValuePropsByGameId = {
  1: ticketValuePropsNewYearGame,
  2: ticketValuePropsTreasureIslandGame,
  3: ticketValuePropsKDPayGame,
  4: ticketValuePropsNewYearGame2024_2025,
}

const gameId = process.env.REACT_APP_GAME_ID
export const ticketValueProps = ticketValuePropsByGameId[gameId]
