import { mainMenuWrapperPropsNewYearGame } from './mainMenuWrapperPropsNewYearGame'
import { mainMenuWrapperPropsTreasureIslandGame } from './mainMenuWrapperPropsTreasureIslandGame'
import { mainMenuWrapperPropsKDPayGame } from './mainMenuWrapperPropsKDPayGame'
import { mainMenuWrapperPropsNewYearGame2024_2025 } from './mainMenuWrapperPropsNewYearGame2024_2025'

const mainMenuWrapperPropsByGameId = {
  1: mainMenuWrapperPropsNewYearGame,
  2: mainMenuWrapperPropsTreasureIslandGame,
  3: mainMenuWrapperPropsKDPayGame,
  4: mainMenuWrapperPropsNewYearGame2024_2025,
}

const gameId = process.env.REACT_APP_GAME_ID
export const mainMenuWrapperProps = mainMenuWrapperPropsByGameId[gameId]
