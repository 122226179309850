import styled from 'styled-components'

export const PopupAlertPanelNewYearGame2024_2025 = styled.div`
  display: flex;
  flex-direction: column;

  width: 100vw; // vw вместо % предотвращает схлопывание элемента
  max-width: 328px;
  min-height: 182px;
  padding: 45px 16px 16px 16px;
  border-radius: 32px;
  background: linear-gradient(180deg, #21bbe0 2.52%, #1aaace 52.86%);
`
