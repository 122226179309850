import { copyTextPropsNewYearGame } from './copyTextPropsNewYearGame'
import { copyTextPropsTreasureIslandGame } from './copyTextPropsTreasureIslandGame'
import { copyTextPropsKDPayGame } from './copyTextPropsKDPayGame'
import { copyTextPropsNewYearGame2024_2025 } from './copyTextPropsNewYearGame2024_2025'

const copyTextPropsByGameId = {
  1: copyTextPropsNewYearGame,
  2: copyTextPropsTreasureIslandGame,
  3: copyTextPropsKDPayGame,
  4: copyTextPropsNewYearGame2024_2025,
}

const gameId = process.env.REACT_APP_GAME_ID
export const copyTextProps = copyTextPropsByGameId[gameId]
