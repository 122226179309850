import { PopupInfoCloseNewYearGame } from './PopupInfoCloseNewYearGame'
import { PopupInfoCloseTreasureIslandGame } from './PopupInfoCloseTreasureIslandGame'
import { PopupInfoCloseKDPayGame } from './PopupInfoCloseKDPayGame'
import { PopupInfoCloseNewYearGame2024_2025 } from './PopupInfoCloseNewYearGame2024_2025'

const PopupInfoCloseByGameId = {
  1: PopupInfoCloseNewYearGame,
  2: PopupInfoCloseTreasureIslandGame,
  3: PopupInfoCloseKDPayGame,
  4: PopupInfoCloseNewYearGame2024_2025,
}

const gameId = process.env.REACT_APP_GAME_ID
export const PopupInfoClose = PopupInfoCloseByGameId[gameId]
