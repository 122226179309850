import styled from 'styled-components'
import { TextPageTitle } from 'ui/atoms'

export const TextTitleTicketsNewYearGame2024_2025 = styled(TextPageTitle)`
  font-size: 62.5px;
  font-weight: 700;
  line-height: 130%;
  letterspacing: 0.5px;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  text-shadow: ${({ theme }) => `2px 2px ${theme.colors.blue[1]}`};
  text-transform: uppercase;
`
