import theme from 'ui/settings/theme'

export const HOME_DESKTOP_BACKGROUND = {
  backgroundColor: theme.colors.blue[6],
  backgroundImage:
    'url(/img/newYearGame2024_2025/home_desktop_logo.png), url(/img/newYearGame2024_2025/home_desktop_bg.png)',
  backgroundPosition: 'right 0 top 2vh, left 0 bottom 0',
  backgroundRepeat: 'no-repeat, no-repeat',
  backgroundSize: 'auto 100%, 100vw auto',
}

export const HOME_MOBILE_BACKGROUND = {
  backgroundColor: theme.colors.blue[7],
  backgroundImage:
    'url(/img/newYearGame2024_2025/home_mobile_logo.png), url(/img/newYearGame2024_2025/home_mobile_bg.png)',
  backgroundPosition: 'center bottom 0, center bottom 0',
  backgroundRepeat: 'no-repeat, no-repeat',
  backgroundSize: {
    _: 'min(386px, max(330px, 100vw)) auto, 1200px auto',
  },
}

export const RULES_BACKGROUND = {
  backgroundImage: 'linear-gradient(180deg, #21BBE0 2.52%, #1AAACE 52.86%)',
  backgroundRepeat: 'no-repeat',
}

export const CALENDARS_MOBILE_BACKGROUND = {
  backgroundImage: `url(/img/newYearGame2024_2025/snow_and_christmas_trees_mobile.png), linear-gradient(0deg, ${theme.colors.blue[12]} 0%, ${theme.colors.blue[11]} 40%, ${theme.colors.blue[7]} 80%)`,
  backgroundPosition: 'left 50% bottom 0, center center',
  backgroundRepeat: 'no-repeat, no-repeat',
  backgroundSize: 'cover, cover',
}

export const CALENDARS_DESKTOP_BACKGROUND = {
  backgroundImage: `url(/img/newYearGame2024_2025/snow_and_christmas_trees.png), linear-gradient(0deg, ${theme.colors.blue[12]} 0%, ${theme.colors.blue[11]} 40%, ${theme.colors.blue[7]} 80%)`,
  backgroundPosition: 'center bottom 0, center center',
  backgroundRepeat: 'no-repeat, no-repeat',
  backgroundSize: {
    _: 'cover, cover',
  },
}

export const CALENDAR_TABLET_BACKGROUND = {
  backgroundColor: theme.colors.blue[13],
  backgroundImage: 'url(/img/newYearGame2024_2025/mobile_board_bg.png)',
  backgroundPosition: 'center bottom',
  backgroundRepeat: 'no-repeat',
  backgroundSize: '900px 1183px',
}

export const CALENDAR_MOBILE_BACKGROUND = {
  backgroundColor: theme.colors.blue[13],
  backgroundImage: 'url(/img/newYearGame2024_2025/mobile_board_bg.png)',
  backgroundPosition: 'center bottom',
  backgroundRepeat: 'no-repeat',
  backgroundSize: '900px 1183px',
}

export const CALENDAR_DESKTOP_BACKGROUND = {
  backgroundColor: theme.colors.blue[13],
}

export const TICKETS_MOBILE_BACKGROUND = {
  backgroundColor: theme.colors.blue[7],
  backgroundImage: `url(/img/newYearGame2024_2025/tickets_mobile_logo.png), url(/img/newYearGame2024_2025/tickets_mobile_bg.png)`,
  backgroundPosition: 'center bottom 0, center bottom 0',
  backgroundRepeat: 'no-repeat, no-repeat',
  backgroundSize: {
    _: 'min(550px, max(500px, calc(100vw + 30%))) auto, 1200px auto',
  },
}

export const TICKETS_DESKTOP_BACKGROUND = {
  backgroundImage: `url(/img/newYearGame2024_2025/snow_and_christmas_trees.png), linear-gradient(0deg, ${theme.colors.blue[12]} 0%, ${theme.colors.blue[11]} 40%, ${theme.colors.blue[7]} 80%)`,
  backgroundPosition: 'center center, center center',
  backgroundRepeat: 'no-repeat, no-repeat',
  backgroundSize: {
    _: 'cover, cover',
  },
}

export const SPONSORS_MOBILE_BACKGROUND = {
  backgroundColor: theme.colors.blue[7],
  backgroundImage:
    'url(/img/newYearGame2024_2025/sponsors_and_raffle_winners_bg.png)',
  backgroundPosition: 'center bottom 0',
  backgroundRepeat: 'no-repeat',
  backgroundSize: {
    _: '1200px auto',
    lg: '100vw auto',
  },
}

export const RAFFLE_WINNERS_MOBILE_BACKGROUND = {
  backgroundColor: theme.colors.blue[7],
  backgroundImage:
    'url(/img/newYearGame2024_2025/sponsors_and_raffle_winners_bg.png)',
  backgroundPosition: 'center bottom 0',
  backgroundRepeat: 'no-repeat',
  backgroundSize: {
    _: '1200px auto',
    lg: '100vw auto',
  },
}
