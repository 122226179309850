import { sparLogoPropsNewYearGame } from './sparLogoPropsNewYearGame'
import { sparLogoPropsTreasureIslandGame } from './sparLogoPropsTreasureIslandGame'
import { sparLogoPropsKDPayGame } from './sparLogoPropsKDPayGame'
import { sparLogoPropsNewYearGame2024_2025 } from './sparLogoPropsNewYearGame2024_2025'

const sparLogoPropsByGameId = {
  1: sparLogoPropsNewYearGame,
  2: sparLogoPropsTreasureIslandGame,
  3: sparLogoPropsKDPayGame,
  4: sparLogoPropsNewYearGame2024_2025,
}

const gameId = process.env.REACT_APP_GAME_ID
export const sparLogoProps = sparLogoPropsByGameId[gameId]
