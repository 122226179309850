import { pathsNewYearGame } from './pathsNewYearGame'
import { pathsTreasureIslandGame } from './pathsTreasureIslandGame'
import { pathsKDPayGame } from './pathsKDPayGame'
import { pathsNewYearGame2024_2025 } from './pathsNewYearGame2024_2025'

const pathsByGameId = {
  1: pathsNewYearGame,
  2: pathsTreasureIslandGame,
  3: pathsKDPayGame,
  4: pathsNewYearGame2024_2025,
}

const gameId = process.env.REACT_APP_GAME_ID
export const PATHS = pathsByGameId[gameId]
