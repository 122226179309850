import styled from 'styled-components'

import { Button, Popup } from 'ui/atoms'
import { BASE_ERROR_MESSAGE_TOP } from 'constants/messages'
import { PopupInfoFrontLogo } from './PopupInfoFrontLogo'
import { popupInfoFrontLogoProps } from './popupInfoFrontLogoProps'
import { PopupInfoBackLogo } from './PopupInfoBackLogo'
import { PopupAlertPanel } from './PopupAlertPanel'
import { buttonProps } from './buttonProps'

const PopupAlertContentTop = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 16px;

  font-weight: 500;
  font-size: 20px;
  line-height: 26px;
  text-align: center;
  letter-spacing: 0.5px;

  color: ${props => props.theme.colors.white};
`

const PopupAlertContentBottom = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 16px;

  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  letter-spacing: 0.5px;

  color: ${props => props.theme.colors.white};
`

export const PopupAlert = ({
  heading = '',
  message = '',
  btnText = 'Хорошо',
  isOpened,
  onClose,
  onSubmit,
}) => (
  <Popup isOpened={isOpened} onClose={onClose}>
    <PopupAlertPanel>
      <PopupInfoBackLogo />
      <PopupInfoFrontLogo {...popupInfoFrontLogoProps} />
      <PopupAlertContentTop>{heading}</PopupAlertContentTop>
      <PopupAlertContentBottom>{message}</PopupAlertContentBottom>
      <Button {...buttonProps} onClick={onSubmit || onClose}>
        {btnText}
      </Button>
    </PopupAlertPanel>
  </Popup>
)
