import { PreloaderThematicNewYearGame } from './PreloaderThematicNewYearGame'
import { PreloaderThematicTreasureIslandGame } from './PreloaderThematicTreasureIslandGame'
import { PreloaderThematicKDPayGame } from './PreloaderThematicKDPayGame'
import { PreloaderThematicNewYearGame2024_2025 } from './PreloaderThematicNewYearGame2024_2025'

const PreloaderThematicByGameId = {
  1: PreloaderThematicNewYearGame,
  2: PreloaderThematicTreasureIslandGame,
  3: PreloaderThematicKDPayGame,
  4: PreloaderThematicNewYearGame2024_2025,
}

const gameId = process.env.REACT_APP_GAME_ID
export const PreloaderThematic = PreloaderThematicByGameId[gameId]
