import { keysBoxWrapperPropsNewYearGame } from './keysBoxWrapperPropsNewYearGame'
import { keysBoxWrapperPropsTreasureIslandGame } from './keysBoxWrapperPropsTreasureIslandGame'
import { keysBoxWrapperPropsKDPayGame } from './keysBoxWrapperPropsKDPayGame'
import { keysBoxWrapperPropsNewYearGame2024_2025 } from './keysBoxWrapperPropsNewYearGame2024_2025'

const keysBoxWrapperPropsByGameId = {
  1: keysBoxWrapperPropsNewYearGame,
  2: keysBoxWrapperPropsTreasureIslandGame,
  3: keysBoxWrapperPropsKDPayGame,
  4: keysBoxWrapperPropsNewYearGame2024_2025,
}

const gameId = process.env.REACT_APP_GAME_ID
export const keysBoxWrapperProps = keysBoxWrapperPropsByGameId[gameId]
