import { useContainerMarginBottomNewYearGame } from './useContainerMarginBottomNewYearGame'
import { useContainerMarginBottomTreasureIslandGame } from './useContainerMarginBottomTreasureIslandGame'
import { useContainerMarginBottomKDPayGame } from './useContainerMarginBottomKDPayGame'
import { useContainerMarginBottomNewYearGame2024_2025 } from './useContainerMarginBottomNewYearGame2024_2025'

const useContainerMarginBottomByGameId = {
  1: useContainerMarginBottomNewYearGame,
  2: useContainerMarginBottomTreasureIslandGame,
  3: useContainerMarginBottomKDPayGame,
  4: useContainerMarginBottomNewYearGame2024_2025,
}

const gameId = process.env.REACT_APP_GAME_ID
export const useContainerMarginBottom = useContainerMarginBottomByGameId[gameId]
