import styled from 'styled-components'

export const PopupFrontLogoKDPayGame = styled.div`
  position: absolute;
  top: -184px;
  left: 50%;
  transform: translateX(-50%);
  width: 372px;
  height: 385px;
  background-image: url(/img/kdPayGame/ticket_and_game_starting_date_popups_heading.png);
  background-size: contain;
  background-repeat: no-repeat;
  z-index: -1;
`
