import { Link, matchPath, useLocation, useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'

import { PATHS } from 'routing/paths'
import { getNavLinkBasedOnLocation } from 'utils/navigation'
import { Box, Flex } from 'ui/atoms'
import styled, { css } from 'styled-components'
import { useMemo } from 'react'
import { useCalendarManager } from 'hooks/useCalendarManager'
import { NavbarWrapper } from './NavbarWrapper'

export const NavbarMobile = ({ goBackURL = PATHS.HOME }) => {
  const { urlSearchParams } = useSelector(state => state.user)
  const location = useLocation()
  const navigate = useNavigate()
  const { giftReceivingAnimationShow } = useCalendarManager()

  const closeUrl = useMemo(() => {
    return urlSearchParams?.closeUrl || ''
  }, [urlSearchParams])

  const isHome = !!matchPath(
    { path: location.pathname, exact: true },
    PATHS.HOME
  )
  const isRulesPage = !!matchPath(
    { path: location.pathname, exact: true },
    PATHS.RULES
  )

  const onBackClick = () => {
    if (isHome) {
      if (!closeUrl) return

      window.location.href = closeUrl
    }

    if (!isHome) {
      navigate({ pathname: getNavLinkBasedOnLocation(location.pathname) })
    }
  }

  return (
    <NavbarWrapper
      justifyContent="space-between"
      minHeight="44px"
      padding="10px 12px"
      $disabled={giftReceivingAnimationShow}
    >
      <Box as="button" w="24px" h="24px" onClick={onBackClick}>
        <img src="/img/arrow_back.svg" alt="" />
      </Box>

      {!isRulesPage && (
        <Flex>
          <Box as="span" href="" w="24px" h="24px" mr="16px">
            <Link to={PATHS.SPONSORS}>
              <img src="/img/basket_icon.png" alt="" />
            </Link>
          </Box>
          <Box as="span" href="" w="24px" h="24px" mr="16px">
            <Link to={PATHS.WINNERS}>
              <img src="/img/solar_cup_star_icon.png" alt="" />
            </Link>
          </Box>
          <Box as="a" href="https://vk.com/spar_kd" w="24px" h="24px" mr="16px">
            <img src="/img/vk_logo_square.png" alt="" />
          </Box>
          <Box as="span" w="24px" h="24px">
            <Link to={PATHS.RULES}>
              <img src="/img/info.svg" alt="" />
            </Link>
          </Box>
        </Flex>
      )}
    </NavbarWrapper>
  )
}
