import styled from 'styled-components'

import { TextPageTitle } from 'ui/atoms'

export const TextTitleSponsorsNewYearGame = styled(TextPageTitle)`
  text-shadow: ${({ theme }) => `2px 2px ${theme.colors.blue[1]}`};
  font-size: 40px;
  font-weight: 500;
  line-height: 130%;
  letter-spacing: 0.5px;
  text-align: center;
  margin: 0 auto;
`
