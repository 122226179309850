import styled from 'styled-components'

export const PopupFrontLogoNewYearGame = styled.div`
  position: absolute;
  top: -146px;
  left: 50%;
  transform: translateX(-50%);
  height: auto;
  width: 328px;
  height: 179px;
  background-image: url(/img/newYearGame/discount_popup_heading.png);
  background-position: center center;
  background-size: contain;
  background-repeat: no-repeat;
`
