import styled from 'styled-components'

export const KeyDescriptionWrapperNewYearGame2024_2025 = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10;
  display: flex;
  flex-direction: column;
  padding: 110px 20px 20px 20px;
  align-items: center;
  justify-content: start;
  background-color: rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(2px) brightness(0.5);
`
