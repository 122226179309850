import { RaffleWinnerItemTextNewYearGame } from './RaffleWinnerItemTextNewYearGame'
import { RaffleWinnerItemTextTreasureIslandGame } from './RaffleWinnerItemTextTreasureIslandGame'
import { RaffleWinnerItemTextKDPayGame } from './RaffleWinnerItemTextKDPayGame'
import { RaffleWinnerItemTextNewYearGame2024_2025 } from './RaffleWinnerItemTextNewYearGame2024_2025'

const RaffleWinnerItemTextByGameId = {
  1: RaffleWinnerItemTextNewYearGame,
  2: RaffleWinnerItemTextTreasureIslandGame,
  3: RaffleWinnerItemTextKDPayGame,
  4: RaffleWinnerItemTextNewYearGame2024_2025,
}

const gameId = process.env.REACT_APP_GAME_ID
export const RaffleWinnerItemText = RaffleWinnerItemTextByGameId[gameId]
