import { useHeadingTextNewYearGame } from './useHeadingTextNewYearGame'
import { useHeadingTextTreasureIslandGame } from './useHeadingTextTreasureIslandGame'
import { useHeadingTextKDPayGame } from './useHeadingTextKDPayGame'
import { useHeadingTextNewYearGame2024_2025 } from './useHeadingTextNewYearGame2024_2025'

const useHeadingTextByGameId = {
  1: useHeadingTextNewYearGame,
  2: useHeadingTextTreasureIslandGame,
  3: useHeadingTextKDPayGame,
  4: useHeadingTextNewYearGame2024_2025,
}

const gameId = process.env.REACT_APP_GAME_ID
export const useHeadingText = useHeadingTextByGameId[gameId]
