import { kdPayLogoPropsNewYearGame } from './kdPayLogoPropsNewYearGame'
import { kdPayLogoPropsTreasureIslandGame } from './kdPayLogoPropsTreasureIslandGame'
import { kdPayLogoPropsKDPayGame } from './kdPayLogoPropsKDPayGame'
import { kdPayLogoPropsNewYearGame2024_2025 } from './kdPayLogoPropsNewYearGame2024_2025'

const kdPayLogoPropsByGameId = {
  1: kdPayLogoPropsNewYearGame,
  2: kdPayLogoPropsTreasureIslandGame,
  3: kdPayLogoPropsKDPayGame,
  4: kdPayLogoPropsNewYearGame2024_2025,
}

const gameId = process.env.REACT_APP_GAME_ID
export const kdPayLogoProps = kdPayLogoPropsByGameId[gameId]
