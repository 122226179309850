import { contentPropsNewYearGame } from './contentPropsNewYearGame'
import { contentPropsTreasureIslandGame } from './contentPropsTreasureIslandGame'
import { contentPropsKDPayGame } from './contentPropsKDPayGame'
import { contentPropsNewYearGame2024_2025 } from './contentPropsNewYearGame2024_2025'

const contentPropsByGameId = {
  1: contentPropsNewYearGame,
  2: contentPropsTreasureIslandGame,
  3: contentPropsKDPayGame,
  4: contentPropsNewYearGame2024_2025,
}

const gameId = process.env.REACT_APP_GAME_ID
export const contentProps = contentPropsByGameId[gameId]
