import styled from 'styled-components'

export const TopSectionLogoWrapperNewYearGame2024_2025 = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 500px;
  height: 80px;
  width: 50%;
`
