import { layoutMobilePropsNewYearGame } from './layoutMobilePropsNewYearGame'
import { layoutMobilePropsTreasureIslandGame } from './layoutMobilePropsTreasureIslandGame'
import { layoutMobilePropsKDPayGame } from './layoutMobilePropsKDPayGame'
import { layoutMobilePropsNewYearGame2024_2025 } from './layoutMobilePropsNewYearGame2024_2025'

const layoutMobilePropsByGameId = {
  1: layoutMobilePropsNewYearGame,
  2: layoutMobilePropsTreasureIslandGame,
  3: layoutMobilePropsKDPayGame,
  4: layoutMobilePropsNewYearGame2024_2025,
}

const gameId = process.env.REACT_APP_GAME_ID
export const layoutMobileProps = layoutMobilePropsByGameId[gameId]
