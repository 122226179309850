import { couponValueTextPropsNewYearGame } from './couponValueTextPropsNewYearGame'
import { couponValueTextPropsTreasureIslandGame } from './couponValueTextPropsTreasureIslandGame'
import { couponValueTextPropsKDPayGame } from './couponValueTextPropsKDPayGame'
import { couponValueTextPropsNewYearGame2024_2025 } from './couponValueTextPropsNewYearGame2024_2025'

const couponValueTextPropsByGameId = {
  1: couponValueTextPropsNewYearGame,
  2: couponValueTextPropsTreasureIslandGame,
  3: couponValueTextPropsKDPayGame,
  4: couponValueTextPropsNewYearGame2024_2025,
}

const gameId = process.env.REACT_APP_GAME_ID
export const couponValueTextProps = couponValueTextPropsByGameId[gameId]
