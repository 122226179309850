import { NavbarWrapperNewYearGame } from './NavbarWrapperNewYearGame'
import { NavbarWrapperTreasureIslandGame } from './NavbarWrapperTreasureIslandGame'
import { NavbarWrapperKDPayGame } from './NavbarWrapperKDPayGame'
import { NavbarWrapperNewYearGame2024_2025 } from './NavbarWrapperNewYearGame2024_2025'

const NavbarWrapperByGameId = {
  1: NavbarWrapperNewYearGame,
  2: NavbarWrapperTreasureIslandGame,
  3: NavbarWrapperKDPayGame,
  4: NavbarWrapperNewYearGame2024_2025,
}

const gameId = process.env.REACT_APP_GAME_ID
export const NavbarWrapper = NavbarWrapperByGameId[gameId]
