import { Flex } from 'ui/atoms'
import { NoticeItem } from 'ui/molecules'

import { RAFFLES_REG_TERM } from 'constants/raffles'
import { getClosestRaffleDate } from 'utils/date'
import { useContainerMarginBottom } from './useContainerMarginBottom'

export const Notice = props => {
  const closestRaffle = getClosestRaffleDate(new Date(), RAFFLES_REG_TERM)
  const containerMarginBottom = useContainerMarginBottom()

  return (
    <Flex
      justifyContent={{ _: 'center', lg: 'flex-start' }}
      {...props}
      {...containerMarginBottom}
    >
      <NoticeItem
        title="100K"
        caption="Сюрпризов ждут тебя"
        w="90px"
        h="90px"
        mr={{ _: '28px', lg: '32px', last: 0 }}
      />

      <NoticeItem
        title="30"
        caption="iPhone 16"
        w="90px"
        h="90px"
        mr={{ _: '28px', lg: '32px', last: 0 }}
      />

      <NoticeItem
        title={closestRaffle?.day}
        subtitle={closestRaffle?.month}
        caption="Ближайший розыгрыш"
        w="90px"
        h="90px"
        display={closestRaffle ? 'block' : 'none'}
      />
    </Flex>
  )
}
