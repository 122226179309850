import { useWideViewportNewYearGame } from './useWideViewportNewYearGame'
import { useWideViewportTreasureIslandGame } from './useWideViewportTreasureIslandGame'
import { useWideViewportKDPayGame } from './useWideViewportKDPayGame'
import { useWideViewportNewYearGame2024_2025 } from './useWideViewportNewYearGame2024_2025'

const useWideViewportByGameId = {
  1: useWideViewportNewYearGame,
  2: useWideViewportTreasureIslandGame,
  3: useWideViewportKDPayGame,
  4: useWideViewportNewYearGame2024_2025,
}

const gameId = process.env.REACT_APP_GAME_ID
export const useWideViewport = useWideViewportByGameId[gameId]
