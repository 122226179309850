import theme from 'ui/settings/theme'

export const subTitleTextPropsNewYearGame2024_2025 = {
  mb: '4px',
  fontSize: { _: '14px', lg: '14px' },
  fontWeight: '700',
  lineHeight: '1',
  textAlign: 'center',
  color: theme.colors.blue[2],
}
