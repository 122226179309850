import styled from 'styled-components'

export const PopupFrontLogoNewYearGame2024_2025 = styled.div`
  position: absolute;
  top: -110px;
  left: 50%;
  transform: translateX(-50%);
  width: 328px;
  height: 144px;
  background-image: url(/img/newYearGame2024_2025/ticket_gift_popup_heading_front.png);
  background-position: center center;
  background-size: contain;
  background-repeat: no-repeat;
  z-index: 1;
`
