import { podedaLogoPropsNewYearGame } from './podedaLogoPropsNewYearGame'
import { podedaLogoPropsTreasureIslandGame } from './podedaLogoPropsTreasureIslandGame'
import { podedaLogoPropsKDPayGame } from './podedaLogoPropsKDPayGame'
import { podedaLogoPropsNewYearGame2024_2025 } from './podedaLogoPropsNewYearGame2024_2025'

const podedaLogoPropsByGameId = {
  1: podedaLogoPropsNewYearGame,
  2: podedaLogoPropsTreasureIslandGame,
  3: podedaLogoPropsKDPayGame,
  4: podedaLogoPropsNewYearGame2024_2025,
}

const gameId = process.env.REACT_APP_GAME_ID
export const podedaLogoProps = podedaLogoPropsByGameId[gameId]
