import styled from 'styled-components'

import { Box, Flex } from '../../atoms'
import theme from 'ui/settings/theme'
import { TopSectionLogo } from './TopSectionLogo'
import { TopSectionLogoContainer } from './TopSectionLogoContainer'
import { topSectionLogoProps } from './topSectionLogoProps'
import { TopSectionLogoWrapper } from './TopSectionLogoWrapper'
import { kdPayLogoProps } from './kdPayLogoProps'
import { sparLogoProps } from './sparLogoProps'
import { familyLogoProps } from './familyLogoProps'
import { podedaLogoProps } from './podedaLogoProps'
import { sparInlineLogoProps } from './sparInlineLogoProps'

export const LogoFooterMobile = () => {
  return (
    <Flex justifyContent="space-between" flexDirection="column">
      <TopSectionLogoContainer>
        <TopSectionLogoWrapper>
          <TopSectionLogo {...topSectionLogoProps} />
        </TopSectionLogoWrapper>
      </TopSectionLogoContainer>
      <Flex
        alignContent="center"
        justifyContent="center"
        backgroundColor={theme.colors.white}
      >
        <Flex
          maxWidth="500px"
          alignContent="center"
          justifyContent="space-between"
          minHeight="20px"
          padding="min(16px, 5%)"
        >
          <Box {...kdPayLogoProps}></Box>
          <Box {...sparLogoProps}></Box>
          <Box {...familyLogoProps}></Box>
          <Box {...podedaLogoProps}></Box>
          <Box {...sparInlineLogoProps}></Box>
        </Flex>
      </Flex>
    </Flex>
  )
}
